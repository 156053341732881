import { TabType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { bannerTabs } from "./banner-settings.constants";

export const useBannerLocalization = () => {
  const { localeStore } = useStores();

  const getTabLabel = (value: string) => {
    switch (value) {
      case "default":
        return localeStore.t('settings["share-settings"]["banner-settings"].tabs.default');
      case "custom":
        return localeStore.t('settings["share-settings"]["banner-settings"].tabs.custom');
      default:
        return "";
    }
  };

  const bannerTabsLocalized: TabType[] = bannerTabs.map(({ value }) => {
    return { value, label: getTabLabel(value) };
  });

  return {
    bannerTabsLocalized,
  };
};
