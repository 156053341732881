import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Image, Modal, Typography, useDebounce } from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store/modals/modals.store.types";
import ReactCrop, { centerCrop, makeAspectCrop, type Crop } from "react-image-crop";
import {
  MAX_CROP_HEIGHT,
  MAX_CROP_WIDTH,
  CROP_ASPECT,
  MIN_CROP_WIDTH,
  MIN_CROP_HEIGHT,
} from "./crop-banner-constants";

import "react-image-crop/dist/ReactCrop.css";
import CropBackgroundImg from "src/external-ts/assets/images/share-settings/crop-background.png";
import styles from "./crop-banner-modal.module.css";
import { useStores } from "src/hooks";

export type CropResultType = {
  cropFile: File;
  cropImg: string;
};

type Options = {
  file: File;
  fileUploadRef: any;
  onSubmit: (cropResult: CropResultType) => void;
};

export type CropBannerModalProps = ModalExtendedType<Options>;

/**
 * @description transfer crop area to file
 */
const _cropImage = async (file: File, crop: Crop, displayWidth: number) => {
  const objectUrl = URL.createObjectURL(file);
  return new Promise<CropResultType>((resolve, reject) => {
    let { x, y, height, width, unit } = crop;

    const img = new window.Image();

    img.onload = () => {
      if (unit === "%") {
        x *= img.width / 100;
        y *= img.height / 100;
        width *= img.width / 100;
        height *= img.height / 100;
      } else if (unit === "px") {
        const scale = img.width / displayWidth;
        x *= scale;
        y *= scale;
        width *= scale;
        height *= scale;
      }
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      ctx?.drawImage(img, x, y, width, height, 0, 0, width, height);
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        const cropFile = new File([blob], file.name, { type: file.type });
        resolve({ cropFile, cropImg: URL.createObjectURL(cropFile) });
      }, file.type);
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      reject(new Error("Image loading error"));
    };

    img.src = objectUrl;
  }).finally(() => {
    URL.revokeObjectURL(objectUrl);
  });
};

const _fitContent = (width: number, height: number, maxWidth: number, maxHeight: number) => {
  const aspectRatio = width / height;

  if (width > maxWidth) {
    width = maxWidth;
    height = width / aspectRatio;
  }

  if (height > maxHeight) {
    height = maxHeight;
    width = height * aspectRatio;
  }

  if (width < maxWidth && height < maxHeight) {
    const scale = Math.min(maxWidth / width, maxHeight / height);
    width *= scale;
    height *= scale;
  }
  return { width, height };
};

export const CropBannerModal: React.FC<CropBannerModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options: { file, fileUploadRef, onSubmit } }) => {
    if (!file) throw new Error("file is empty");
    const cropAreaRef = useRef<HTMLDivElement>(null);
    const cropBackgroundImg = useRef<HTMLImageElement>(null);
    const { localeStore } = useStores();
    const [crop, setCrop] = useState<Crop>();
    const imgRef = useRef<HTMLImageElement>(null);
    const [imgSrc, setImgSrc] = useState("");
    const [imgWidth, setImgWidth] = useState(0);
    let resizeTimeout = useRef(0);

    useEffect(() => {
      const url = URL.createObjectURL(file);
      setImgSrc(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }, [file]);

    useEffect(() => {
      window.addEventListener("resize", handleResetCrop);

      return () => {
        window.removeEventListener("resize", handleResetCrop);
        if (resizeTimeout.current) cancelAnimationFrame(resizeTimeout.current);
      };
    }, []);

    const handleResetCrop = () => {
      if (resizeTimeout.current) cancelAnimationFrame(resizeTimeout.current);
      resizeTimeout.current = requestAnimationFrame(() => {
        resetCrop(imgRef.current);
      });
    };

    const resetCrop = (img: any) => {
      let { clientWidth = MAX_CROP_WIDTH, clientHeight = MAX_CROP_HEIGHT } =
        cropAreaRef.current as HTMLDivElement;
      if (clientHeight === 0) clientHeight = (clientWidth / MAX_CROP_WIDTH) * MAX_CROP_HEIGHT;
      const { width, height } = _fitContent(img.width, img.height, clientWidth, clientHeight);
      setImgWidth(width);
      let cropWidth = width;
      let cropHeight = height;
      let x = 0;
      let y = 0;
      if (width / height > CROP_ASPECT) {
        cropWidth = height * CROP_ASPECT;
        const x = (width - cropWidth) / 2;
      } else if (width / height < CROP_ASPECT) {
        cropHeight = width / CROP_ASPECT;
        y = (height - cropHeight) / 2;
      } else {
        setCrop({
          x,
          y,
          width: 100,
          height: 100,
          unit: "%",
        });
        return;
      }
      setCrop({
        x,
        y,
        width: cropWidth,
        height: cropHeight,
        unit: "px",
      });
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
      resetCrop(e.currentTarget);
    };

    const handleSubmit = async () => {
      if (!crop) return;
      const cropResult = await _cropImage(file, crop, imgRef.current?.width || imgWidth);
      onSubmit(cropResult);
    };

    const changeBanner = async () => {
      const inputElement = fileUploadRef.current.querySelector("input");
      inputElement && inputElement.click();
    };

    return (
      <Modal
        name="crop-banner-modal"
        data-cy="crop-banner-modal"
        scrollWrapperClassName={styles.modalScrollWrapper}
        contentClassName={styles.container}
        isOpen={isOpen}
        withCross={true}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
      >
        <div className={styles.cropBannerHeader}>
          <Typography className={styles.title} color="textSecondary">
            {localeStore.t('settings["share-settings"]["banner-settings"]["crop-info"].title')}
          </Typography>
        </div>
        <div ref={cropAreaRef} className={styles.cropBannerContainer}>
          <Image className={styles.cropBannerBackground} src={CropBackgroundImg} />
          {imgSrc && (
            <ReactCrop
              className={[styles.cropBox, "ReactCrop--no-animate"].join(" ")}
              aspect={CROP_ASPECT}
              minHeight={MIN_CROP_HEIGHT}
              minWidth={MIN_CROP_WIDTH}
              keepSelection={true}
              crop={crop}
              onChange={(crop) => {
                setCrop(crop);
              }}
            >
              <img
                ref={imgRef}
                width={imgWidth ? imgWidth + "px" : "auto"}
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <Button
          className={styles.cropBannerReset}
          size="medium"
          appearance="primaryGhost"
          onClick={changeBanner}
        >
          {localeStore.t('settings["share-settings"]["banner-settings"]["crop-info"].subtitle')}
        </Button>
        <div className={styles.cropBannerFooter}>
          <Button
            className={styles.cancleButton}
            size="small"
            appearance="tertiary"
            onClick={setClose}
          >
            {localeStore.t(
              'settings["share-settings"]["banner-settings"]["crop-info"].button.cancel',
            )}
          </Button>
          <Button
            className={styles.saveButton}
            size="small"
            appearance="primary"
            onClick={handleSubmit}
          >
            {localeStore.t(
              'settings["share-settings"]["banner-settings"]["crop-info"].button.save',
            )}
          </Button>
        </div>
      </Modal>
    );
  },
);
