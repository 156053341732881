export const copyText = (function () {
  let copyMethod: (text: string, copySuccess: Function) => void;

  return function (text: string, copySuccess: Function) {
    if (!copyMethod) {
      if (navigator.clipboard) {
        copyMethod = (text, copySuccess) => {
          navigator.clipboard
            .writeText(text)
            .then(() => {
              copySuccess();
            })
            .catch((error) => {
              console.error("Failed to copy text: ", error);
            });
        };
      } else {
        copyMethod = (text, copySuccess) => {
          const input = document.createElement("input");
          input.style.position = "absolute";
          input.style.left = "-9999px";
          input.style.opacity = "0";
          input.setAttribute("value", text);
          document.body.appendChild(input);
          input.select();
          try {
            document.execCommand("copy");
            copySuccess();
          } catch (error) {
            console.error("Failed to copy text: ", error);
          }
          document.body.removeChild(input);
        };
      }
    }

    copyMethod(text, copySuccess);
  };
})();

export const testPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,64}$/;
