import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import {
  Checkbox,
  Loader,
  SvgIcon,
  Button,
  EllipseActions,
  ActionOption,
  clsx,
} from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";
import { useStores } from "src/hooks";
import { MediaType, PendingUploadStatus } from "src/models";
import { downloadMedias, proFeatureTooltip } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { AR_MEDIA_PAGE } from "src/constants";
import { MediaItemSku } from "./media-item-sku/media-item-sku";

import { ReactComponent as VideoSVG } from "src/external-ts/assets/images/media/video-type.svg";
import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as Video360SVG } from "src/external-ts/assets/images/media/video360-type.svg";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/eye-grey.svg";
import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-grey.svg";
import { ReactComponent as BlinkSVG } from "src/external-ts/assets/images/media/blink-type.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as GeneratePostSVG } from "src/external-ts/assets/images/fountain-pen-grey.svg";
import { ReactComponent as SocialsSVG } from "src/external-ts/assets/images/socials-icons.svg";
import { ReactComponent as ProBadgeSVG } from "src/external-ts/assets/images/badges/pro-badge-yellow.svg";
import { ReactComponent as DownloadCloudSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import styles from "./media-item.module.css";
import { ShareInfoType } from "src/hooks/use-share.hook";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";

export type MediaItemProps = {
  className?: string;
  item: ExtendedMediaModel;
  onSelect: (item: ExtendedMediaModel) => void;
  disabled?: boolean;
  selected?: boolean;
  loading?: boolean;
  disableActions?: boolean;
  disableSelect?: boolean;
  selectOnClick?: boolean;
  style?: React.CSSProperties;
  handleShareMedia?: (shareInfoType: ShareInfoType) => void;
};

export const MediaItem: React.FC<MediaItemProps> = observer(
  ({
    className,
    item,
    onSelect,
    disableActions = false,
    disabled = false,
    disableSelect = false,
    selectOnClick = false,
    selected = false,
    loading = false,
    style,
    handleShareMedia,
  }) => {
    const navigate = useNavigate();

    const { mediaStore, modalsStore, localeStore, tooltipStore, subscriptionsStore } = useStores();

    const { sku, type, extended, id } = item;
    // ?. crutch for <MediaItem /> usage in <ProductAssignMediaSidebar />
    const isSelected = selected || extended?.selected;
    const isLoading = loading || extended?.loading;

    const { canBlinks, canGeneratePosts } = useLimits();

    const isUploading = item?.status === PendingUploadStatus.pending;
    const isBlink = type === "blink";
    const isImage = type === "image";

    // NOTE: Using 'item?.arTemplateId' due to not all blinks are editable, but only one's,
    // that has necessary fields
    const canShowEditMediaBlink = isBlink && !!item?.arTemplateId;
    const canShowEditMediaImage = isImage;
    const canShowEdit = canShowEditMediaBlink || canShowEditMediaImage;

    const isEditDisabled = isBlink && !canBlinks;

    const handleBlinkPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (canBlinks) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleBlinkPointerLeave = () => {
      if (canBlinks) tooltipStore.close();
    };

    const handleSelect = () => onSelect(item);

    const handleEditPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (!isEditDisabled) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleEditPointerLeave = () => {
      if (!isEditDisabled) tooltipStore.close();
    };

    const handleEditClick = () => {
      if (canShowEditMediaImage) navigate(`/media/${item.id}/edit`);

      if (canShowEditMediaBlink) {
        navigate(AR_MEDIA_PAGE.path, {
          state: {
            media: toJS(item),
            type: "media",
            redirect: location.pathname,
          },
        });
      }
    };

    const handleShare = () => {
      handleShareMedia &&
        handleShareMedia({
          resourceType: ResourceType.MEDIA,
          resourceIds: [item.id],
          shareType: ShareType.SHARE_MEDIA,
        });
    };

    const handleDownload = () => downloadMedias([item]);

    const handleCreateBlink = () => {
      navigate(AR_MEDIA_PAGE.path, {
        state: {
          media: { ...toJS(item), productType: null },
          type: "media",
        },
      });
    };

    // const handleGeneratePost = () => {
    //   modalsStore.open("ShareModal", { type: "media", data: item, tab: "generatePost" });
    // };

    const handlePointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (subscriptionsStore.isProSubscription) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleDelete = () => {
      modalsStore.open("DeleteMediaModal", { media: [item] });
    };

    const handleClick = () => {
      if (disableActions || selectOnClick) return;
      if (item.type === "pdf") {
        modalsStore.open("PdfPreviewModal", { url: item.file.original, name: item.filename });
        return;
      }
      handleShareMedia &&
        modalsStore.open("MediaDetailsSidebar", {
          data: item,
          handleShareMedia,
        });
    };

    const getMediaPreview = () => {
      let result: any = item.thumbnail;
      if (type === MediaType.blink && item?.arTemplateId) result = item.cropFile?.small;
      if (isUploading)
        return (
          <div className={styles.pendingUpWrapper}>
            <SvgIcon icon={PendingUpSVG} size={65} />
          </div>
        );

      return <img src={result} alt="" loading="lazy" />;
    };

    const checkIfCanSelectOnClick = () => {
      let result = selectOnClick;
      if (disabled) result = false;
      return result;
    };

    const mediaItemWrapperStyles = clsx(styles.mediaItemWrapper, className);

    return (
      <div
        className={mediaItemWrapperStyles}
        onClick={checkIfCanSelectOnClick() ? handleSelect : undefined}
        style={style}
        data-cy="media-list-item"
        data-sku={sku}
        data-id={id}
        data-assigned={!!sku}
        data-created-by={item.created._id}
        data-type={item.type}
      >
        {isLoading && <Loader position="absolute" withOverlay />}
        {mediaStore.isMediaSelected && (
          <div className={clsx(styles.buttonWrapper, { [styles.selected]: isSelected })}>
            <Button className={styles.selectBtn} appearance="secondary" size="small">
              {isSelected && <SvgIcon className={styles.checkmarkWrapper} icon={CheckmarkSVG} />}
              {isSelected
                ? localeStore.t('media["media-list"]["media-item"].buttons.unselect')
                : localeStore.t('media["media-list"]["media-item"].buttons.select')}
            </Button>
          </div>
        )}
        <div className={styles.header}>
          {!disableSelect && (
            <Checkbox
              className={clsx(styles.checkbox, { [styles.selected]: mediaStore.isMediaSelected })}
              onClick={(e) => e.stopPropagation()}
              onChange={handleSelect}
              checked={isSelected}
              disabled={!isSelected && disabled}
              disableError
              data-cy="media-list-item-checkbox"
            />
          )}

          {!disableActions && (
            <EllipseActions
              tooltipClassName={
                sku ? styles.actionsTooltipContainerSKU : styles.actionsTooltipContainer
              }
              appearance="quaternary"
              size="tiny"
              data-cy="media-list-item-actions"
            >
              {canShowEdit && (
                <ActionOption
                  onPointerEnter={handleEditPointerEnter}
                  onPointerLeave={handleEditPointerLeave}
                  onClick={handleEditClick}
                  disabled={isEditDisabled || isUploading}
                  data-cy="media-item-edit"
                >
                  <SvgIcon icon={EditSVG} />
                  {localeStore.t("common.buttons.edit")}
                </ActionOption>
              )}
              <ActionOption onClick={handleShare} data-cy="media-item-share" disabled={isUploading}>
                <SvgIcon icon={ShareSVG} />
                {localeStore.t("common.buttons.share")}
              </ActionOption>
              <ActionOption
                onClick={handleDownload}
                data-cy="media-item-download"
                disabled={isUploading}
              >
                <SvgIcon icon={DownloadCloudSVG} />
                {localeStore.t("common.buttons.download")}
              </ActionOption>
              {type === MediaType.image && !item.metaData?.isModelImage && (
                <ActionOption
                  onPointerEnter={handleBlinkPointerEnter}
                  onPointerLeave={handleBlinkPointerLeave}
                  onClick={handleCreateBlink}
                  disabled={!canBlinks || isUploading}
                  data-cy="media-item-create-blink"
                >
                  <SvgIcon icon={BlinkSVG} disableAutoColor />
                  {localeStore.t(
                    'media["media-list"]["media-item"]["media-actions"]["create-try-on-link"]',
                  )}
                </ActionOption>
              )}
              {/* {sku && item.type !== "pdf" && (
                <ActionOption
                  onClick={handleGeneratePost}
                  data-cy="media-item-generate-post"
                  className={styles.generatePostAction}
                  disabled={!canGeneratePosts || isUploading}
                  onPointerEnter={handlePointerEnter}
                >
                  <SvgIcon icon={GeneratePostSVG} />
                  {localeStore.t(
                    'media["media-list"]["media-item"]["media-actions"]["generate-ai-post"]',
                  )}
                  <SvgIcon icon={SocialsSVG} className={styles.socialsIcons} />
                  <SvgIcon icon={ProBadgeSVG} className={styles.proBadge} />
                </ActionOption>
              )} */}
              <ActionOption onClick={handleDelete} data-cy="media-item-delete">
                <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
                {localeStore.t("common.buttons.delete")}
              </ActionOption>
            </EllipseActions>
          )}
        </div>
        <div
          className={clsx(styles.imageContainer, { [styles.disableBodyClick]: disableActions })}
          onClick={handleClick}
        >
          <div className={styles.views}>
            <SvgIcon icon={EyeSVG} size={16} />
            {item.views}
          </div>
          <div className={styles.imageFiller} />
          <div className={clsx(styles.imageWrapper, { [styles.pdfWrapper]: item.type === "pdf" })}>
            {item.type === "pdf" ? (
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <SvgIcon icon={PdfSVG} size={80} />
              </div>
            ) : (
              getMediaPreview()
            )}
          </div>
        </div>
        <div className={clsx(styles.footer, { [styles.big]: type === MediaType.image })}>
          {type !== MediaType.image && (
            <div className={styles.iconWrapper}>
              {type === MediaType.video && <SvgIcon icon={VideoSVG} />}
              {type === MediaType.video360 && <SvgIcon icon={Video360SVG} />}
              {type === MediaType.blink && <SvgIcon icon={BlinkSVG} />}
              {type === MediaType.pdf && <SvgIcon icon={PdfSVG} />}
            </div>
          )}

          <div className={styles.textWrapper}>
            <MediaItemSku data={item} />
            <div className={styles.dateWrapper}>{moment(item.created_at).format("MM/DD/YYYY")}</div>
          </div>
        </div>
      </div>
    );
  },
);
