import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import {
  queryStringify,
  Select,
  SelectOption,
  useCall,
  useDebounce,
  useDidMount,
  Nullable,
  InputDOMElement,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { getProducts, postProduct } from "src/api";
import { useStores } from "src/hooks";

import styles from "../camera-preview-sidebar.module.css";
import { ProductImageModel, ProductModel } from "src/models";
import { ReactComponent as NoSkuFoundSVG } from "src/external-ts/assets/images/camera/no-sku-found-icon.svg";

export interface DropdownOptionType extends SelectOption<number> {
  src?: string;
  source?: ProductModel;
}

interface SKUSelectProps {
  selectedSKUOption: DropdownOptionType | undefined;
  onSKUChange: (value: DropdownOptionType | undefined) => void;
  required?: boolean;
}

const SKUSelectForwardRefRenderFunction: React.ForwardRefRenderFunction<any, SKUSelectProps> = (
  { selectedSKUOption, onSKUChange, required = false },
  ref,
) => {
  const { debounce } = useDebounce(100);
  const { localeStore, productsStore } = useStores();

  const [options, setOptions] = useState<DropdownOptionType[]>([]);
  const [search, setSearch] = useState<Nullable<string>>(null);
  const [isSearchUnique, setSearchUnique] = useState(false);
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<InputDOMElement>(null);

  const lodingOptions = (rows: ProductModel[]) => {
    setLoading(false);
    const options = rows.map((item) => {
      const { title, _id, images } = item;
      return {
        label: title,
        value: _id,
        src: getImgSrc(images),
        source: item,
      };
    });
    const doesExist = rows.find(({ title }) => title === search);

    setOptions(options);
    setSearchUnique(!doesExist);
  };

  const getImgSrc = (images?: ProductImageModel[]) => {
    if (images && images.length > 0) {
      const src = images.find(({ file }) => file?.small)?.file?.small;
      if (src) return src;
    }
    return "";
  };
  const getProductsCall = useCall(getProducts.setQueryParams(queryStringify({ title: search })));
  getProductsCall.onCallSuccess(({ rows }) => {
    lodingOptions(rows);
  });

  const createProductCall = useCall(postProduct);
  createProductCall.onCallSuccess(async ({ rows, total_items }) => {
    productsStore.sendCreateProductEvent(total_items);

    lodingOptions(rows);

    const product = rows[0];

    if (!search) return;

    handleChange({ label: search, value: product._id, source: product });
    setCreating(false);
  });

  const handleSearch = (value: string) => {
    setSearch(value);

    debounce(() => {
      setLoading(true);
      getProductsCall.submit();
    });
  };

  const handleChange = (option: Nullable<DropdownOptionType> | undefined) => {
    if (option === undefined) return;

    if (option === null) {
      setSearch("");
      onSKUChange(undefined);
    }

    if (option !== null) {
      const { value, label } = option;
      if (!value || !label) return;
      setSearch(label);
      onSKUChange(option);
    }
  };

  const handleCreateProduct = () => {
    if (!search) return;
    setCreating(true);
    createProductCall.submit({ data: { title: search } });
  };

  useDidMount(() => {
    setLoading(true);
    getProductsCall.submit();
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    setOptions: (options: DropdownOptionType[]) => setOptions(options),
    handleSearch,
    handleCreateProduct,
  }));

  return (
    <>
      {creating && (
        <div className={styles.loadingBox}>
          <span className={styles.loadingPoint}></span>
          <span className={styles.loadingPoint}></span>
          <span className={styles.loadingPoint}></span>
        </div>
      )}
      <Select
        appearance="primaryV2"
        label={localeStore.t('camera["camera-preview-sidebar"]["sku-select"]["choose-sku"]')}
        placeholder={
          creating
            ? ""
            : localeStore.t('camera["camera-preview-sidebar"]["sku-select"].placeholder')
        }
        selectedOptionsKeys={selectedSKUOption}
        options={options}
        onChange={handleChange}
        onSearch={handleSearch}
        onCreateNew={handleCreateProduct}
        canCreate={isSearchUnique}
        isTextEditable
        disableError
        data-hj-allow
        disabled={creating}
        loading={loading}
        required={required}
        forwardRef={inputRef}
        useThumbnails={true}
        addItemText={localeStore.t('camera["camera-preview-sidebar"]["sku-select"]["save-sku"]')}
        noContentText={
          <div className={styles.noContentText}>
            <SvgIcon icon={NoSkuFoundSVG} size={[36, 38]} />
            <span>
              {localeStore.t('camera["camera-preview-sidebar"]["sku-select"]["no-found-SKU"]')}
            </span>
          </div>
        }
        createNewText={
          <div className={styles.noContentText}>
            <SvgIcon icon={NoSkuFoundSVG} size={[36, 38]} />
            <span>
              "{search}"{" "}
              {localeStore.t('camera["camera-preview-sidebar"]["sku-select"]["does-not-exist"]')}
            </span>
            <div className={styles.btnCreateSku}>
              {localeStore.t('camera["camera-preview-sidebar"]["sku-select"]["save-sku"]')}
            </div>
          </div>
        }
        dropdownClassName={styles.dropdown}
      />
    </>
  );
};

export const SKUSelect = forwardRef(SKUSelectForwardRefRenderFunction);
