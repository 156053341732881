import { gql } from "urql";

export const SHARE_SETTINGS_MUTATION = gql`
  mutation ($input: UserUpdateShareSettingsInput!) {
    userUpdateShareSettings(input: $input) {
      data
    }
  }
`;

export const SHARE_SETTINGS_QUERY = gql`
  query {
    userShareSettings {
      bannerImages {
        filename
        interactive
        original
        size
        createdAt
        medium
        small
        preview
        compressed
        transcoded
      }
      companyLogo {
        filename
        interactive
        original
        size
        createdAt
        medium
        small
        preview
        compressed
        transcoded
      }
      companyName
      companyWebsite
      companyDescription
      contactEmail
      contactWhatsup
      contactPhone
    }
  }
`;

export const LINK_RESOLVE = gql`
  mutation ($resourceType: ResourceTypeResolve!, $resourceIds: [Int!]!) {
    linkResolve(resourceType: $resourceType, resourceIds: $resourceIds) {
      id
      uuid
      resourceId
      resourceType
      createdAt
      updatedAt
      shareLink
      expandAttributes
      showBanner
      showCompanyLogo
      showCompanyInfo
      showContactBtn
      showDownloadBtn
      showShoppingCart
      passwordPlain
      passwordSet
      bannerSet
    }
  }
`;

export const LINK_UPDATE = gql`
  mutation ($id: Int!, $input: LinkUpdateInput!) {
    linkUpdate(id: $id, input: $input) {
      data
    }
  }
`;
