import { ShareSettingsFormType } from "./share-settings.types";

export const initialValues: ShareSettingsFormType = {
  company_name: "",
  company_phone: {
    country: "US",
    phone: "",
  },
  company_email: "",
  company_website: "",
  company_description: "",
  company_logo: null,
  contact_whatsup: "",
  contact_phone: {
    country: "US",
    phone: "",
  },
  contact_email: "",
  is_shown_similiar: true,
  is_shown_gallery_info: false,
  isShowBuyButtonOnShareLinks: true,
  enable_download: true,
};

export enum ScrollTo {
  BANNER = "BANNER",
  COMPANY_LOGO = "COMPANY_LOGO",
  COMPANY_INFO = "COMPANY_INFO",
  CONTACT_BUTTON = "CONTACT_BUTTON",
}

export const FORM_ID = "share-settings-form";
