import React, { useState } from "react";
import {
  Modal,
  Heading,
  Typography,
  Image,
  SvgIcon,
  Button,
  clsx,
  memo,
} from "@gemlightbox/core-kit";

import { getSharedResourceLink } from "src/external-ts/components/business/share-modal/shared-link-content/share-link-modal.utils";
import { useStores } from "src/hooks";
import {
  downloadMedias,
  downloadProducts,
  getMediaPreviewFromProduct,
  getMediaPreview,
} from "src/utils";
import { WellDoneModalProps } from "./well-done-modal.types";

import { ReactComponent as NoMediaSVG } from "src/external-ts/assets/images/media/no-media-grey.svg";
import { ReactComponent as UploadCloudSVG } from "src/external-ts/assets/images/upload-cloud-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as ClickSVG } from "src/external-ts/assets/images/click-grey.svg";

import styles from "./well-done-modal.module.css";

export const WellDoneModal: React.FC<WellDoneModalProps> = memo(
  ({ isOpen, options, setClose, onFinalClosed }) => {
    const { type, message, clickHerePreText, data, onShare, onClose, onDownload } = options;
    const messageToShow = message?.trim();

    const { modalsStore, tooltipStore, localeStore } = useStores();

    const [link] = useState(() => {
      const link = getSharedResourceLink(type, data);

      if (type === "product") {
        return `${link}?tryMeOn=true`;
      }

      return link;
    });

    const [thumbnail] = useState(() => {
      if (type === "product") {
        return getMediaPreviewFromProduct((data as any).images[0]);
      }
      if (type === "media") return getMediaPreview(data as any);
      return "";
    });

    const handleDownload = () => {
      if (type === "product") downloadProducts(data as any, false);
      if (type === "media") downloadMedias(data as any, false, onClose);

      setClose();
      onDownload?.();
    };

    // const handleShare = () => {
    //   setClose();
    //   modalsStore.open("ShareModal", { data, type, onClose, tab: "shareLink" });

    //   onShare?.();
    // };

    const handleOpenTooltip = (text: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      tooltipStore.open(<Typography size="extraSmall">{text}</Typography>, {
        target: e.currentTarget,
        appearance: "secondary",
        position: "bottom",
        withAngle: true,
      });
    };

    const handleClose = () => {
      setClose();
      onClose?.();
    };

    const handleTooltipClose = () => tooltipStore.close();

    return (
      <Modal
        scrollWrapperClassName={styles.wellDoneModalScrollWrapper}
        contentClassName={styles.wellDoneModalContent}
        isOpen={isOpen}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
        withCross
        data-cy="well-done-modal"
      >
        <Heading className={styles.wellDoneHeading} tag="h2" color="textSecondary">
          {localeStore.t('components.business["well-done-modal"].title')}
        </Heading>
        <div className={clsx(styles.wellDoneImageWrapper, { [styles.showBG]: !thumbnail })}>
          {thumbnail && <Image src={thumbnail} objectFit="scaleDown" loading="eager" />}
          {!thumbnail && <SvgIcon icon={NoMediaSVG} size={236} />}
        </div>
        {messageToShow && (
          <Typography className={styles.wellDoneMessage} size="small" color="textTertiary">
            {messageToShow}
          </Typography>
        )}
        <div className={styles.wellDoneActionsWrapper}>
          <a
            className={styles.clickHereButtonWrapper}
            href={link}
            target="_blank"
            rel="noreferrer"
            onClick={handleClose}
          >
            <Heading tag="h4">{clickHerePreText}</Heading>
            <Heading className={styles.clickHereHeader} tag="h4">
              {localeStore.t('components.business["well-done-modal"]["click-here"].first')}{" "}
              <SvgIcon icon={ClickSVG} />
            </Heading>
            <Heading tag="h4">
              {localeStore.t('components.business["well-done-modal"]["click-here"].last')}
            </Heading>
          </a>
          <Button
            appearance="tertiaryOutlined"
            onClick={handleDownload}
            onMouseEnter={handleOpenTooltip(
              localeStore.t('components.business["well-done-modal"].tooltip.upload'),
            )}
            onMouseLeave={handleTooltipClose}
          >
            <SvgIcon icon={UploadCloudSVG} />
          </Button>
          {/* <Button
            appearance="tertiaryOutlined"
            onClick={handleShare}
            onMouseEnter={handleOpenTooltip(
              localeStore.t('components.business["well-done-modal"].tooltip.share'),
            )}
            onMouseLeave={handleTooltipClose}
          >
            <SvgIcon icon={ShareSVG} />
          </Button> */}
        </div>
      </Modal>
    );
  },
);

export default WellDoneModal;
