import { config } from "src/config/environment";
import { userStorage } from "src/redux/reducers/user";

export const getProductLink = (uuid: string, customDomain?: string) => {
  let result = `${config.galleryURL}/s/${uuid}`;

  if (customDomain) result = `https://${customDomain}/s/${uuid}`;

  return result;
};

export const getProductPdfLink = (productIds: number[], customDomain?: string | undefined) => {
  const { token } = userStorage.get();
  const ids = JSON.stringify(productIds);
  let result = `${config.galleryURL}/print/${ids}?token=${token}`;

  if (customDomain) result = `https://${customDomain}/print/${ids}?token=${token}`;

  return result;
};
