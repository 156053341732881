import { observer } from "mobx-react-lite";
import { Modal } from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store/modals/modals.store.types";
import { ShareType } from "./share-setting-modal.constants";
import {
  LinkInfo,
  LinkUpdateReq,
} from "src/api/graphql-api/share-settings/share-settings.interface";

import styles from "./share-setting-modal.module.css";
import { ShareSetting } from "./share-setting";

type Options = {
  shareType: ShareType;
  onLoad: () => Promise<LinkInfo | undefined>;
  onSubmit: (id: number, linkUpdateReq: LinkUpdateReq) => void;
};

export type ShareSettingModalProps = ModalExtendedType<Options>;

export const ShareSettingModal: React.FC<ShareSettingModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options: { shareType, onLoad, onSubmit } }) => {
    return (
      <Modal
        name="share-modal"
        data-cy="share-modal"
        scrollWrapperClassName={styles.modalScrollWrapper}
        contentClassName={
          [ShareType.SHARE_MEDIA, ShareType.SHARE_MEDIAS].includes(shareType)
            ? styles.shareMediaModal
            : styles.shareSettingModal
        }
        isOpen={isOpen}
        withCross={true}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
      >
        <ShareSetting shareType={shareType} onLoad={onLoad} onSubmit={onSubmit}></ShareSetting>
      </Modal>
    );
  },
);
