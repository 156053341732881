/* eslint-disable react/prop-types */
import React from "react";
import _ from "lodash";
import { useStores } from "src/hooks";
import syncSuccessImage from "src/common/images/sync-success.svg";
import styles from "./index.module.css";

const SyncProductSuccess = () => {
  const { localeStore } = useStores();

  return (
    <div className={styles.wrapper}>
      <img className={styles.syncSuccessIcon} src={syncSuccessImage} />

      <div className="modal-content__header">
        <div className={styles.syncSuccessText}>
          {localeStore.t(
            'settings.integrations.modals["integration-errors-modal"]["sync-success"]',
          )}
        </div>
      </div>
    </div>
  );
};

export default SyncProductSuccess;
