import { bytesConverter, TabType } from "@gemlightbox/core-kit";

const BannerTypeNames = {
  default: "default",
  custom: "custom",
};

export const bannerTabs: TabType[] = Object.entries(BannerTypeNames).map(([value, label]) => {
  const result: TabType = {
    value,
    label,
  };

  return result;
});

export const bannerUploaderAccept = "image/jpeg, image/jpg, image/png";
export const bannerMaxSizeInBytes = bytesConverter(10, "Bytes", "MB");
export const bannerMaxFiles = 1;
