import React, { useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { ApiRequest, ExtractApiResponse, Nullable, useDidMount } from "@gemlightbox/core-kit";

import { getUserTagManagerInfoCallback, postAIGenerateImageDescriptions } from "src/api";
import { PageContainer, PageHeader } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { CREATE_PAGE, PRODUCT_CREATE_PAGE } from "src/constants";
import { ExtendedMediaModel } from "src/store";
import { MediaList } from "../media-list";
import { aiProductDescriptionMediaLimit } from "./ai-product-description.constants";
import { pushDataLayerEvent } from "src/utils";

import styles from "../../../components/pages/product/product-attributes/product-attributes.module.css";
import WSConnection from "../../../../common/socket";

export const AIProductDescription: React.FC = observer(() => {
  const navigate = useNavigate();

  const { userStore, modalsStore, localeStore, subscriptionsStore, notificationStore } =
    useStores();

  const selectedMediasRef = useRef<ExtendedMediaModel[]>([]);

  const requestRef =
    useRef<Nullable<ApiRequest<ExtractApiResponse<typeof postAIGenerateImageDescriptions>>>>(null);
  const requestIdRef = useRef("");

  const handleOpenDescriptionSidebar = (initialDescriptions?: string[], infos?: any) => {
    if (!selectedMediasRef.current) return;

    modalsStore.open("AIDescriptionBuilderSidebar", {
      initialTitle: infos.detailedTitle,
      initialProductType: infos.productType,
      initialDescriptions,
      onDescriptionSelect: ({ productType, description, detailedTitle }) => {
        navigate(PRODUCT_CREATE_PAGE.path, {
          state: JSON.stringify({
            type: "ai-description",
            initialImages: toJS(selectedMediasRef.current),
            initialFormValues: {
              detailedTitle,
              productType,
              description,
            },
          }),
        });
      },
    });
  };

  const handleGoBack = () => navigate(-1);

  const handleAIDescriptionProduct = async (medias: ExtendedMediaModel[]) => {
    selectedMediasRef.current = medias;
    const media = medias[0];

    notificationStore.openLoader({
      loaderType: "progress-circle",
      contentClassName: styles.loader,
      progress: 0,
    });

    const formData = new FormData();
    formData.append("media_ids", media.id.toString());
    formData.append("use_gpt", "true");
    formData.append("language", userStore.aiLanguage);
    const request = postAIGenerateImageDescriptions.getRequest({
      data: formData,
    });
    requestRef.current = request;

    request.events.on("requestStart", ({ headers }) => {
      requestIdRef.current = headers["x-request-id"];
    });

    const requestResult = await request.fetch();

    notificationStore.closeLoader();
    if (requestResult.status === "cancelled") return;
    if (requestResult.error) {
      notificationStore.open({
        title: localeStore.t('app["error-modal"].title'),
        icon: "crossRoundedFilled",
        confirmAppearance: "error",
        confirmText: localeStore.t("common.buttons.ok"),
        cancelText: "",
        onlyConfirm: true,
      });
      return;
    }

    userStore.loadUserMeSilently();

    getUserTagManagerInfoCallback((response) => {
      pushDataLayerEvent({
        event: "gemhub:product:ai_description:received",
        user_id: response.user_id,
        account_type: response.account_type,
        is_trial: response.isTrial,
      });
    });

    handleOpenDescriptionSidebar(
      [
        requestResult.success.gpt_description_1,
        requestResult.success.gpt_description_2,
        requestResult.success.gpt_description_3,
      ],
      {
        detailedTitle: requestResult.success.product_title,
        productType: requestResult.success.jewelry_type,
      },
    );
  };

  useDidMount(() => {
    const userId = userStore.userMe?.user._id;

    if (!userId) return;

    const progressWS = new WSConnection(`/user/${userId}`, userStore.token);

    progressWS.on("progress/UPDATE", ({ payload }: any) => {
      if (requestIdRef.current === payload.requestID) {
        notificationStore.openLoader({
          loaderType: "progress-circle",
          progress: payload.progress,
          contentClassName: styles.loader,
        });

        if (payload.progress === 100) notificationStore.closeLoader();
      }
    });

    return () => {
      progressWS.destroy();
    };
  });

  if (
    !userStore.isSubscribed &&
    (userStore.userUsage?.countGPTDescriptionsAttemptMonth ?? 0) >=
      subscriptionsStore.limits.countGPTDescription
  )
    return <Navigate to={CREATE_PAGE.path} />;

  return (
    <PageContainer>
      <PageHeader
        title={localeStore.t('create["ai-product-description"].title')}
        onBack={handleGoBack}
      />
      <MediaList
        subTitle={localeStore.t('create["ai-product-description"].subtitle')}
        actionBtnText={localeStore.t('create["ai-product-description"]["action-btn-text"]')}
        limit={aiProductDescriptionMediaLimit}
        onActionClick={handleAIDescriptionProduct}
        withoutSku
        isAIDescription
        type="image"
      />
    </PageContainer>
  );
});

export default AIProductDescription;
