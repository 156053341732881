import { Button, Modal, SvgIcon, clsx } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import { useStores } from "src/hooks";
import { ReactComponent as ScenarioWhiteGoldSVG } from "src/external-ts/assets/images/camera/scenario-white-gold.svg";
import { ReactComponent as ScenarioLooseDiamondSVG } from "src/external-ts/assets/images/camera/scenario-loose-diamond.svg";
import { ReactComponent as ScenarioNaturalHueSVG } from "src/external-ts/assets/images/camera/scenario-natural-hue.svg";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-icon.svg";
import FilterSceneTutorialBannerJPG from "src/external-ts/assets/images/camera/filter-scene-tutorial-banner.jpg";
import styles from "./filter-scene-tutorial-modal.module.css";
import { ModalExtendedType } from "src/store";

export type FilterSceneTutorialModalProps = ModalExtendedType<{ onFinalOpened?: () => void }>;

export const FilterSceneTutorialModal: React.FC<FilterSceneTutorialModalProps> = observer(
  ({ isOpen, setClose, options }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollContent}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalOpened={options?.onFinalOpened}
        disableBackdropClose
        name="generated-sku-tutorial-modal"
        data-cy="generated-sku-tutorial-modal"
      >
        <img className={styles.banner} src={FilterSceneTutorialBannerJPG} />
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <span className={styles.titleBig}>
              {localeStore.t('camera["filter-scene-tutorial-modal"].title')}
            </span>
            <div className={styles.descriptionContainer}>
              <div className={clsx(styles.description)}>
                <div className={styles.icon}>
                  <SvgIcon icon={ScenarioWhiteGoldSVG} size={[51, 48]} />
                </div>
                <div className={styles.label}>
                  <span>
                    {localeStore.t('camera["filter-scene-tutorial-modal"]["white-gold"].label')}
                  </span>
                  <span>
                    {localeStore.t(
                      'camera["filter-scene-tutorial-modal"]["white-gold"].description',
                    )}
                  </span>
                </div>
              </div>
              <div className={clsx(styles.description)}>
                <div className={styles.icon}>
                  <SvgIcon icon={ScenarioLooseDiamondSVG} size={[51, 48]} />
                </div>
                <div className={styles.label}>
                  <span>
                    {localeStore.t('camera["filter-scene-tutorial-modal"]["loose-diamond"].label')}
                  </span>
                  <span>
                    {localeStore.t(
                      'camera["filter-scene-tutorial-modal"]["loose-diamond"].description',
                    )}
                  </span>
                </div>
              </div>
              <div className={clsx(styles.description)}>
                <div className={styles.icon}>
                  <SvgIcon icon={ScenarioNaturalHueSVG} size={[51, 48]} />
                </div>
                <div className={styles.label}>
                  <span>
                    {localeStore.t('camera["filter-scene-tutorial-modal"]["natural-hue"].label')}
                  </span>
                  <span>
                    {localeStore.t(
                      'camera["filter-scene-tutorial-modal"]["natural-hue"].description',
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.btn} onClick={setClose}>
            {localeStore.t('camera["generated-sku-tutorial-modal"].ok')}
          </Button>
        </div>
        <Button
          className={styles.cross}
          appearance="secondaryGhost"
          onClick={setClose}
          data-cy="cross-btn"
        >
          <SvgIcon color="#fff" icon={CrossSVG} size={32} />
        </Button>
      </Modal>
    );
  },
);

export default FilterSceneTutorialModal;
