import { extendObservable, isObservable, makeAutoObservable, runInAction } from "mobx";
import { Vec2, Webgl2New, imageSourceToCanvas, loadImage } from "@gemlightbox/core-kit";
import { getLogoWatermark, postLogoWatermark, deleteLogoWatermark, postUploadFiles } from "src/api";
import { LogoWatermarkModel, LogoWatermarkRatioModel, MediaType } from "src/models";
import CameraRenderer from "../camera.renderer";

export type LogoWatermarkStatusType = "none" | "loading" | "uploading" | "changing" | "saving";

export type ExtendedLogoWatermarkModel = LogoWatermarkModel & {
  componentId: number;
};
class LogoWatermarkModalStore {
  private _logoWatermarkStatus: LogoWatermarkStatusType;
  public get logoWatermarkStatus() {
    return this._logoWatermarkStatus;
  }
  public renderer: Webgl2New.Webgl2dRenderer;

  private _parentRef: HTMLElement | null;

  private _canvasSize: Vec2;
  public get canvasSize() {
    return this._canvasSize;
  }

  private _logoWatermarkList: ExtendedLogoWatermarkModel[] = [];
  public get logoWatermarkList() {
    return this._logoWatermarkList;
  }

  private _waitDeletionIds: number[] = [];

  public resolutionData = [
    { name: "1:1", value: new Vec2(1000, 1000) },
    { name: "4:3", value: new Vec2(1000, 750) },
    { name: "16:9", value: new Vec2(1000, 562.5) },
  ];

  private _initStore() {
    this._parentRef = null;
    this.renderer = null as any;
  }

  public mount(parent: HTMLElement, canvasSize: Vec2, isLoderData = true) {
    if (!parent || !canvasSize) return;

    this.unmount();

    runInAction(() => {
      this._parentRef = parent;
      this._canvasSize = canvasSize;
      this.renderer = new Webgl2New.Webgl2dRenderer();
    });

    Webgl2New.Webgl2.initialize().then(async () => {
      if (isLoderData) {
        await this.loadLogoWatermarkList();
      }
      await this.renderer.mount(this._parentRef, this.canvasSize);
      this.handleInitializeWebgl2();
    });
  }

  public unmount() {
    this.renderer?.unmount();
    this._initStore();
  }

  public handleInitializeWebgl2() {
    this.renderer.camera.events.offAll();
    // renderer.camera.events.offEvent("change");
    // renderer.camera.events.on("change", (camera) => {
    //   console.log("camera change", camera);
    // });
    Webgl2New.Texture.defaultPixelTextureColor = [255, 255, 255, 120];
    const templateTexture = new Webgl2New.Texture();
    const templateComponent = new Webgl2New.ImageRectComponent(templateTexture);
    templateComponent.transform.setSize(this.canvasSize);
    templateComponent.style.pointerEvents = "none";
    this.renderer.addComponent(templateComponent);
    this.renderer.eventsManager.assignTool(this.renderer.tools.selectionTool);
    this.rendererLogoWatermark();
  }

  public rendererLogoWatermark() {
    runInAction(async () => {
      for (const item of this.logoWatermarkList) {
        const image = await loadImage(item.logo, true);
        const texture = new Webgl2New.Texture(image);
        const imageComponent = new Webgl2New.ImageAdjustRectComponent(texture);
        item.componentId = imageComponent.id;

        const graphicProperties = this.getGraphicProperties(item.ratios, this.canvasSize);
        this.setImageComponentAttributes(imageComponent, graphicProperties);
        this.renderer.addComponent(imageComponent);
        this.renderer.selectComponent(imageComponent);
      }
    });
  }

  public async loadLogoWatermarkList() {
    const request = getLogoWatermark.getRequest();
    const requestResult = await request.fetch();
    await runInAction(async () => {
      if (requestResult.success) {
        this._logoWatermarkList = requestResult.success.map((logoWatermark) =>
          this.extendLogoWatermark(logoWatermark),
        );
      } else {
        this._logoWatermarkList = [];
      }
    });
    this._waitDeletionIds = [];
  }

  public async rendererLogoWatermarkByCameraRenderer(canvasSize: Vec2) {
    if (!this._logoWatermarkList || this._logoWatermarkList.length === 0) {
      await this.loadLogoWatermarkList();
    }

    const imageComponentLogoList = [];
    for (const item of this.logoWatermarkList) {
      const image = await loadImage(item.logo, true);
      const texture = new Webgl2New.Texture(image);
      const imageComponent = new Webgl2New.ImageAdjustRectComponent(texture);
      let graphicProperties = this.getGraphicProperties(item.ratios, canvasSize);
      const resolution = this.getResolution(this.getAspectRatio(canvasSize));
      if (resolution && graphicProperties) {
        const { width, height } = canvasSize;
        const wRatio = width / resolution.value.width;
        const hRatio = height / resolution.value.height;

        graphicProperties = {
          ...graphicProperties,
          size: [graphicProperties.size[0] * wRatio, graphicProperties.size[1] * hRatio],
          position: [
            graphicProperties.position[0] * wRatio,
            graphicProperties.position[1] * hRatio,
          ],
        };
      }
      this.setImageComponentAttributes(imageComponent, graphicProperties, canvasSize);
      imageComponentLogoList.push(imageComponent);
    }

    return imageComponentLogoList;
  }

  public getResolution(aspectRatio: string) {
    return this.resolutionData.find((f) => f.name === aspectRatio);
  }

  public getAspectRatio(width: number, height: number): string;
  public getAspectRatio(vec: Vec2): string;
  public getAspectRatio(_a: any, _b?: any): string {
    const w = typeof _a === "number" ? _a : _a.width;
    const h = typeof _b === "number" ? _b : _a.height;

    const ratio = this.gcd(w, h);
    return `${w / ratio}:${h / ratio}`;
  }

  private gcd(w: number, h: number): number {
    return h === 0 ? w : this.gcd(h, w % h);
  }

  private getGraphicProperties(list?: Array<LogoWatermarkRatioModel>, canvasSize?: Vec2) {
    if (!list || !canvasSize) return;
    return list.find((f) => {
      return this.getAspectRatio(canvasSize) === f.aspectRatio;
    });
  }

  private setImageComponentAttributes(
    imageComponent: Webgl2New.ImageAdjustRectComponent,
    graphicProperties?: LogoWatermarkRatioModel,
    canvasSize: Vec2 = this.canvasSize,
  ) {
    if (graphicProperties) {
      imageComponent.transform.calcDimensions({
        position: new Vec2(graphicProperties.position[0], graphicProperties.position[1]),
        oX: 0,
        oY: 0,
        rotation: graphicProperties.rotation,
        size: new Vec2(graphicProperties.size[0], graphicProperties.size[1]),
      });
      if (Number.isFinite(graphicProperties.opacity))
        imageComponent.state.filters.u_opacity = graphicProperties.opacity;
    } else {
      const newImgSize = this.calculateNewImageSize(imageComponent.transform.size, canvasSize);
      imageComponent.transform.setSize(newImgSize);
      imageComponent.transform.setTranslation((canvasSize.width - newImgSize.width) / 2, 8);
    }
  }

  public async rendererLogoWatermarkFile(originalFile: File) {
    runInAction(() => (this._logoWatermarkStatus = "uploading"));
    const logoUrl = await this.uploadMediaSync(originalFile);
    runInAction(() => (this._logoWatermarkStatus = "none"));
    if (!logoUrl) return;

    const image = await loadImage(logoUrl, true);
    const texture = new Webgl2New.Texture(image);
    const imageComponent = new Webgl2New.ImageAdjustRectComponent(texture);
    this.logoWatermarkList.push({
      logo: logoUrl,
      componentId: imageComponent.id,
    });

    this.setImageComponentAttributes(imageComponent);
    this.renderer.addComponent(imageComponent);
    this.renderer.selectComponent(imageComponent);
  }

  private calculateNewImageSize(originalSize: Vec2, canvasSize: Vec2 = this.canvasSize) {
    const wRatio = canvasSize.width / 4;
    return new Vec2(wRatio, wRatio * (originalSize.height / originalSize.width));
  }

  public async changeLogoWatermark(originalFile: File) {
    runInAction(() => (this._logoWatermarkStatus = "changing"));
    const logoUrl = await this.uploadMediaSync(originalFile);
    if (!logoUrl) return;
    const selectedComponent = this.renderer
      .selectedComponent as unknown as Webgl2New.ImageAdjustRectComponent;
    if (!selectedComponent) return;

    const logoWatermarkInfo = this.logoWatermarkList.find(
      (f) => f.componentId === selectedComponent.id,
    );

    if (!logoWatermarkInfo) return;

    const image = await loadImage(logoUrl, true);
    const texture = new Webgl2New.Texture(image);

    const imageComponent = new Webgl2New.ImageAdjustRectComponent(texture);
    logoWatermarkInfo.componentId = imageComponent.id;
    logoWatermarkInfo.logo = logoUrl;

    const graphicProperties = {
      position: selectedComponent.transform.translation.getArray(),
      rotation: selectedComponent.transform.rotation.value,
      size: this.calculateNewImageSize(imageComponent.transform.size, this.canvasSize).getArray(),
      opacity: selectedComponent.state.filters.u_opacity,
      aspectRatio: this.getAspectRatio(this.canvasSize),
    };

    logoWatermarkInfo.ratios?.forEach((ratio) => {
      ratio.size = graphicProperties.size;
    });

    this.setImageComponentAttributes(imageComponent, graphicProperties);
    this.renderer.removeComponent(selectedComponent);
    this.renderer.addComponent(imageComponent);
    this.renderer.selectComponent(imageComponent);
    runInAction(() => (this._logoWatermarkStatus = "none"));
  }

  public selectLogoWatermark(componentId: number) {
    const component = this.renderer.components.find((f) => f.id === componentId);
    this.renderer.selectComponent(component);
  }

  public async deleteLogoWatermarkComponent(logoWatermark: ExtendedLogoWatermarkModel) {
    const logoWatermarkList = this.logoWatermarkList.filter(
      (f) => f.componentId !== logoWatermark.componentId,
    );

    runInAction(() => {
      if (logoWatermark.id) this._waitDeletionIds.push(logoWatermark.id);
      this._logoWatermarkList = logoWatermarkList ?? [];
    });
    const component = this.renderer.components.find((f) => f.id === logoWatermark.componentId);
    if (component) {
      this.renderer.removeComponent(component);
      if (this.renderer.selectedComponent?.id === logoWatermark.componentId) {
        if (logoWatermarkList.length > 0) {
          const component = this.renderer.components.find(
            (f) => f.id === logoWatermarkList[0].componentId,
          );
          this.renderer.selectComponent(component);
        } else {
          this.renderer.selectComponent(null);
        }
      }
    }
  }

  public logoWatermarkComponentChange() {
    this.renderer.components.map((component) => {
      if (component instanceof Webgl2New.ImageAdjustRectComponent) {
        const logoWatermarkInfo = this.logoWatermarkList.find(
          (f) => f.componentId === component.id,
        );

        if (logoWatermarkInfo) {
          const graphicProperties = {
            position: component.transform.translation.getArray(),
            rotation: component.transform.rotation.value,
            size: component.transform.size.getArray(),
            opacity: component.state.filters.u_opacity,
            aspectRatio: this.getAspectRatio(this.canvasSize),
          };

          if (!logoWatermarkInfo.ratios || logoWatermarkInfo.ratios.length === 0) {
            logoWatermarkInfo.ratios = [graphicProperties];
          } else {
            const graphicPropertiesList = logoWatermarkInfo.ratios.filter(
              (f) => f.aspectRatio !== graphicProperties.aspectRatio,
            );
            if (graphicPropertiesList) {
              graphicPropertiesList?.push(graphicProperties);
              logoWatermarkInfo.ratios = graphicPropertiesList;
            }
          }
        }
      }
    });
  }

  public extendLogoWatermark(logoWatermark: LogoWatermarkModel): ExtendedLogoWatermarkModel {
    if (isObservable(logoWatermark)) return logoWatermark as ExtendedLogoWatermarkModel;
    return extendObservable<LogoWatermarkModel, ExtendedLogoWatermarkModel>(logoWatermark, {
      ...logoWatermark,
      componentId: 0,
    });
  }

  public async uploadMediaSync(originalFile: File) {
    const formData = new FormData();
    formData.append("files", originalFile);
    formData.append("types", MediaType.image);

    const request = postUploadFiles.getRequest({
      data: formData,
    });
    const requestResult = await request.fetch();
    if (requestResult.success) {
      return requestResult.success[0].original;
    }
    return null;
  }

  public async deleteLogoWatermark() {
    if (this._waitDeletionIds && this._waitDeletionIds.length > 0) {
      const result = deleteLogoWatermark.getRequest({
        queryParams: { ids: this._waitDeletionIds.join(",") },
      });

      result.events.on("success", () => {
        runInAction(() => (this._waitDeletionIds = []));
      });

      return result.fetch();
    }
  }

  public async updataLogoWatermark() {
    this.logoWatermarkComponentChange();

    const data = this.logoWatermarkList.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ componentId, user_id, createdAt, updatedAt, ...rest }) => rest,
    );
    const result = postLogoWatermark.getRequest({ data });

    result.events.on("success", (res) => {
      runInAction(async () => {
        if (res.success) {
          this._logoWatermarkList = res.success.map((logoWatermark) =>
            this.extendLogoWatermark(logoWatermark),
          );
        } else {
          this._logoWatermarkList = [];
        }
      });
    });
    return result.fetch();
  }

  public async saveLogoWatermark() {
    runInAction(() => (this._logoWatermarkStatus = "saving"));
    const promises = [];
    promises.push(await this.deleteLogoWatermark());
    promises.push(await this.updataLogoWatermark());
    runInAction(() => (this._logoWatermarkStatus = "none"));
    return promises;
  }

  public async setLogoWatermarkToImage(url: string) {
    return Webgl2New.Webgl2.initialize().then(async () => {
      const templateImage = await loadImage(url, true);
      const renderer = new CameraRenderer();
      const canvasSize = new Vec2(templateImage.width, templateImage.height);
      const div = document.createElement("div");
      await renderer.mount(div, templateImage.width, templateImage.height);
      Webgl2New.Webgl2.gl.enable(Webgl2New.Webgl2.gl.BLEND);
      Webgl2New.Webgl2.gl.blendFuncSeparate(
        Webgl2New.Webgl2.gl.SRC_ALPHA,
        Webgl2New.Webgl2.gl.ONE_MINUS_SRC_ALPHA,
        Webgl2New.Webgl2.gl.ONE,
        Webgl2New.Webgl2.gl.ONE_MINUS_SRC_ALPHA,
      );
      const templateTexture = new Webgl2New.Texture(templateImage);
      const templateComponent = new Webgl2New.ImageRectComponent(templateTexture);

      templateComponent.transform.setSize(canvasSize);
      templateComponent.style.pointerEvents = "none";

      renderer.addComponent(templateComponent);

      const logoWatermarkImageComponent =
        await logoWatermarkModalStore.rendererLogoWatermarkByCameraRenderer(canvasSize);
      if (logoWatermarkImageComponent) {
        for (const component of logoWatermarkImageComponent) {
          renderer.addComponent(component);
        }
      }

      const webgl2 = Webgl2New.Webgl2;
      const prevViewport = webgl2.camera.projection;
      webgl2.camera.save();

      // Setup block
      webgl2.setViewport(canvasSize);
      webgl2.camera.copy(renderer.camera as any);

      renderer.renderComponents();
      const canvas = imageSourceToCanvas(webgl2.canvas);

      webgl2.setViewport(prevViewport);
      webgl2.camera.restore();

      const base64 = canvas.toDataURL("image/png");
      renderer.unmount();
      return base64;
    });
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const logoWatermarkModalStore = new LogoWatermarkModalStore();
