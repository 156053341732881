import React from "react";
import { observer } from "mobx-react-lite";

import Modal from "src/components/modals/Modal";
import OverlayLoader from "src/components/OverlayLoader";
import SyncProductError from "../SyncProductError";
import SyncProductSuccess from "../SyncProductSuccess";
import { useStores } from "src/hooks";

import "./index.scss";

export const IntegrationErrorsModal = observer(
  ({ isOpened, onClose, loading, totalItems, failedItems, errors, taskId, errorCount }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        isOpened={isOpened}
        className="integration-errors-modal"
        withClose
        handleModalClose={onClose}
      >
        <div className="modal-content__title">
          {localeStore.t('settings.integrations.modals["integration-errors-modal"].report.title')}
        </div>
        {errorCount && errorCount > 0 ? (
          <SyncProductError
            errorCount={errorCount}
            totalItems={totalItems}
            failedItems={failedItems}
            taskId={taskId}
            errors={errors}
          />
        ) : (
          <SyncProductSuccess />
        )}

        {loading && <OverlayLoader />}
      </Modal>
    );
  },
);

export default IntegrationErrorsModal;
