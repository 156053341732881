import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { Button, ButtonAppearanceKeys, Modal, clsx } from "@gemlightbox/core-kit";
import { ModalExtendedType } from "src/store/modals/modals.store.types";

import styles from "./custom-notification-modal.module.css";

export type CustomNotificationModalProps = ModalExtendedType<{
  title: Nullable<string> | React.ReactNode;
  message?: Nullable<string | React.ReactNode>;
  icon?: Nullable<ReactElement | React.ReactNode>;
  confirmAppearance?: ButtonAppearanceKeys;
  cancelAppearance?: ButtonAppearanceKeys;
  contentClassName?: string;
  titleClassName?: string;
  messageClassName?: string;
  confirmText: string;
  cancelText?: string;
  onOk?: VoidFunction | (() => Promise<void>);
  onClose?: VoidFunction;
  withCross?: boolean;
}>;

export const CustomNotificationModal: React.FC<CustomNotificationModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const {
      confirmAppearance = "primary",
      cancelAppearance = "tertiaryOutlined",
      contentClassName = "",
      titleClassName = "",
      messageClassName = "",
      title,
      message,
      confirmText,
      cancelText,
      icon,
      withCross,
      onClose,
      onOk,
    } = options;
    const handleConfirm = () => {
      const callback = onOk?.();

      if (callback instanceof Promise) {
        callback.finally(() => {
          setClose();
          onClose?.();
        });
        return;
      }

      setClose();
      onClose?.();
    };

    const handleCancel = () => {
      const callback = onClose?.();

      if (callback instanceof Promise) {
        callback.finally(() => {
          setClose();
        });
        return;
      }

      setClose();
    };

    const handleClose = () => {
      setClose();
      onClose?.();
    };

    return (
      <Modal
        contentClassName={styles.modalContent}
        name="notification-modal"
        data-cy="notification-modal"
        isOpen={isOpen}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
        disableBackdropClose
        withCross={withCross}
      >
        <div className={clsx(styles.content, contentClassName)}>
          {icon && <div className={styles.iconWrapper}>{icon}</div>}
          <span className={clsx(styles.notificationTitle, titleClassName)}>{title}</span>
          {message && (
            <span className={clsx(styles.notificationDesc, messageClassName)}>{message}</span>
          )}
          <div className={styles.btnWrappers}>
            {cancelText && (
              <Button
                appearance={cancelAppearance}
                className={styles.notificationBtn}
                onClick={handleCancel}
                data-cy="cancel-button"
              >
                <span>{cancelText}</span>
              </Button>
            )}

            <Button
              appearance={confirmAppearance}
              className={styles.notificationBtn}
              onClick={handleConfirm}
              data-cy="ok-button"
            >
              <span>{confirmText}</span>
            </Button>
          </div>
        </div>
      </Modal>
    );
  },
);
