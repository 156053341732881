import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Tabs,
  Typography,
  Image,
  FileUploadFullscreen,
  Button,
  SvgIcon,
  DropzoneErrorCodes,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ViewExample } from "../view-example";
import { useBannerLocalization } from "./banner-settings.utils";
import {
  bannerMaxFiles,
  bannerMaxSizeInBytes,
  bannerUploaderAccept,
} from "./banner-settings.constants";

import { ReactComponent as CircleWarningSVG } from "src/external-ts/assets/images/circle-warning-icon-red.svg";
import BannerEnImage from "src/external-ts/assets/images/share-settings/banner-en.jpg";
import BannerExampleImage from "src/external-ts/assets/images/share-settings/banner-example.jpg";
import { ReactComponent as DeleteBannerSVG } from "src/external-ts/assets/images/share-settings/delete-banner.svg";
import styles from "./banner-settings.module.css";
import { CropResultType } from "src/external-ts/components/business/crop-banner-modal";
import { MediaFile } from "src/api/graphql-api/share-settings/share-settings.interface";
import { UploadToolTip } from "src/external-ts/components/business/tooltips/upload-tooltip/upload-tooltip";

type BannerSettingsProps = {
  bannerRef: React.RefObject<HTMLDivElement>;
  resetBanner: boolean;
  banner: MediaFile | string | null;
  initialBanner: MediaFile | string | null;
  onBannerChange: (cropResult: CropResultType | null) => void;
};

export const BannerSettings: React.FC<BannerSettingsProps> = observer(
  ({ bannerRef, resetBanner, banner, initialBanner, onBannerChange }) => {
    const { localeStore, modalsStore, tooltipStore } = useStores();
    const [currentTab, setCurrentTab] = useState<string>("default");

    const fileUploadRef = useRef<HTMLDivElement>(null);
    const { bannerTabsLocalized } = useBannerLocalization();
    const bannerToSet = resetBanner ? initialBanner : banner;
    const [bannerPreview, setBannerPreview] = useState("");

    useEffect(() => {
      if (typeof bannerToSet === "string") {
        setBannerPreview(bannerToSet);
      } else if (typeof bannerToSet?.small === "string") {
        setBannerPreview(bannerToSet.small);
      }
    }, [bannerToSet]);

    const handleTabChange = (type: string) => {
      setCurrentTab(type);
    };

    const handleDropAccepted = async (acceptedFiles: File[]) => {
      modalsStore.open("CropBannerModal", {
        file: acceptedFiles[0],
        fileUploadRef,
        onSubmit: (cropResult: CropResultType) => {
          onBannerChange(cropResult);
          modalsStore.close("CropBannerModal");
        },
      });
    };

    const handleDropRejected = async (rejectedFiles: any) => {
      if (rejectedFiles[0]?.reasons[0]?.code === DropzoneErrorCodes.TOO_BIG_CODE) {
        tooltipStore.open(
          <UploadToolTip>
            <SvgIcon icon={CircleWarningSVG} size={16} />
            {localeStore.t("settings.logo.tip.size")}
          </UploadToolTip>,
          {
            target: document.body,
            appearance: "promo",
            position: "bottomRight",
          },
          {
            timeout: 5000,
          },
        );
      }
    };

    const handleRemoveBanner = () => {
      onBannerChange(null);
    };

    const openContactInfoModal = () => {
      modalsStore.open("ViewExampleModal", {
        size: 720,
        title: `${localeStore.t('settings["share-settings"].link')}`,
        subTitle: [
          `${localeStore.t('settings["share-settings"]["banner-settings"]["example-info"].title')}`,
        ],
        description: [
          `${localeStore.t(
            'settings["share-settings"]["banner-settings"]["example-info"].descriptions',
          )}`,
        ],
        images: [BannerExampleImage],
      });
    };

    return (
      <div ref={bannerRef} className={styles.container} data-cy="banner-settings">
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('settings["share-settings"]["banner-settings"].title')}
        </Heading>
        <div className={styles.descriptionContainer} data-cy="product-settings-text-section">
          <Typography className={styles.subTitle} size="small600" color="textSecondary">
            {localeStore.t('settings["share-settings"]["banner-settings"].subtitle')}
          </Typography>
          <Typography className={styles.description} size="extraSmall" color="textTertiary">
            {localeStore.t('settings["share-settings"]["banner-settings"].description')}{" "}
            <ViewExample callback={openContactInfoModal} />
          </Typography>
        </div>
        <div className={styles.cardTabs}>
          <Tabs
            tabs={bannerTabsLocalized}
            currentTab={currentTab}
            onChange={handleTabChange}
            data-cy={currentTab}
          />
        </div>
        <div className={styles.bannerContainer}>
          {currentTab === "default" ? (
            <Image src={BannerEnImage} />
          ) : bannerPreview ? (
            <div className={styles.customBanner}>
              <Image src={bannerPreview} className={styles.bannerImage} />
              <Button
                appearance="primaryGhost"
                className={styles.deleteBannerBtn}
                onClick={handleRemoveBanner}
              >
                <SvgIcon icon={DeleteBannerSVG} />
              </Button>
            </div>
          ) : (
            <FileUploadFullscreen
              data-cy="upload-conatiner"
              className={styles.uploadContainer}
              forwardRef={fileUploadRef}
              title=""
              subtitle=""
              orText={localeStore.t(
                'settings["share-settings"]["banner-settings"]["crop-info"].tip',
              )}
              uploadButtonText={localeStore.t(
                'settings["share-settings"]["banner-settings"]["crop-info"]["submit-text"]',
              )}
              backdropText={localeStore.t('create["file-upload-full-screen"]["backdrop-text"]')}
              accept={bannerUploaderAccept}
              maxSize={bannerMaxSizeInBytes}
              maxFiles={bannerMaxFiles}
              onDropAccepted={handleDropAccepted}
              onDropRejected={handleDropRejected}
              preventDropOnDocument
              // eslint-disable-next-line react/no-children-prop
              children={undefined}
            />
          )}
        </div>
      </div>
    );
  },
);

export default BannerSettings;
