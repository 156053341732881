import React, { useRef, useState } from "react";
import { Heading, Link, Tooltip, Image, Button } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getWhatsappShareLink, getFacebookShareLink } from "src/utils";
import { _getLinkList } from "../share-setting-modal/share-setting";

import styles from "./share-options.module.css";

interface ShareOptionsProps {
  link: string;
}

export const ShareOptions: React.FC<ShareOptionsProps> = ({ link }) => {
  const { localeStore, linkedInStore } = useStores();

  const [tooltipOpen, setTooltip] = useState(false);
  const refLI = useRef(null);

  const handleWhatsapp = () => window.open(getWhatsappShareLink(link), "_blank");

  const handleFacebook = () => window.open(getFacebookShareLink(link), "_blank");

  const handleMail = () => window.open(`mailto:?body=${link}`, "_self");

  const handleLinkedIn = async () => {
    setTooltip(true);
    await linkedInStore.shareLink(link);
    setTimeout(() => setTooltip(false), 3000);
  };

  const linkList = _getLinkList(link, "title", "description");

  return (
    <div className={styles.container}>
      <Heading tag="h3" color="textSecondary">
        {localeStore.t('components.business["share-options"].title')}
      </Heading>

      <div className={styles.iconsContainer}>
        {linkList.map((linkItem, index) => {
          return (
            <Link href={linkItem.href} target={linkItem.target || "_blank"} key={index}>
              <Image src={linkItem.icon} size={48} style={{ borderRadius: "8px" }} />
            </Link>
          );
        })}
      </div>

      <Tooltip
        target={refLI}
        isOpen={tooltipOpen}
        appearance="secondary"
        onClose={setTooltip}
        withAngle
      >
        {linkedInStore.loading
          ? "..."
          : localeStore.t(
              'components.business["share-modal"]["generate-post-content"]["post-preview"]["linkedin-tooltip"]',
            )}
      </Tooltip>
    </div>
  );
};
