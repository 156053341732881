import React, { useState, useRef } from "react";
import {
  Button,
  SvgIcon,
  clsx,
  Nullable,
  Tooltip,
  useBoolean,
  Video,
  useDidUpdate,
  Image,
  Loader,
  useDidUnmount,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products";
import { CameraMediaType } from "../../camera.types";
import { cameraStore } from "src/external-ts/pages/camera/camera.store";

import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import { ReactComponent as PlaySVG } from "src/external-ts/assets/images/play-grey.svg";
import styles from "./media-preview.module.css";
import "./media-preview.css";
import { GlobalInstance } from "src/utils/global";

type MediaPreviewProps = {
  mediaList: CameraMediaType[];
  setClose: VoidFunction;
  onDelete: (imageSrc: string) => void;
  hideAddButton?: boolean;
  showLoadingPreview?: boolean;
  showLoadingRmBG?: boolean;
};

export const MediaPreview: React.FC<MediaPreviewProps> = ({
  setClose,
  mediaList,
  onDelete,
  hideAddButton = false,
  showLoadingPreview = false,
  showLoadingRmBG = false,
}) => {
  const { gemcamPreviewVideo } = cameraStore;
  const { localeStore } = useStores();

  const [mainMediaPreview, setMainMediaPreview] =
    useState<Nullable<HTMLImageElement | HTMLVideoElement>>(null);
  const [activePreviewImageIndex, setActivePreviewImageIndex] = useState<number>(0);
  const [medias, setMedias] = useState(mediaList);

  const tooltipBoolean = useBoolean();

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const updateMainMedia = (newMainMedia: HTMLImageElement | HTMLVideoElement) => {
    setMainMediaPreview(newMainMedia);
  };

  const handlePreviewMediaClick = (media: HTMLImageElement | HTMLVideoElement, index: number) => {
    updateMainMedia(media);
    setActivePreviewImageIndex(index);
  };

  const handleDelete = (e: React.MouseEvent, imageSrc: string | undefined, index: number) => {
    e.stopPropagation();

    const newActiveIndex =
      index < activePreviewImageIndex ? activePreviewImageIndex - 1 : activePreviewImageIndex;

    setMedias(medias.filter((media) => media?.element.src !== imageSrc));
    onDelete(imageSrc || "");
    setActivePreviewImageIndex(newActiveIndex);
  };

  const handleOpenTooltip = () => tooltipBoolean.setTruthy();

  const handleCloseTooltip = () => tooltipBoolean.setFalsy();

  const renderMainMedia = () => {
    if (mainMediaPreview instanceof HTMLImageElement) {
      return <Image className={styles.imageContainer} src={mainMediaPreview.src} />;
    } else {
      return (
        <Video
          controlsClassName="videoBox"
          className={styles.videoContainer}
          src={mainMediaPreview?.src || gemcamPreviewVideo?.element.src || ""}
          loop={!GlobalInstance.isManualStopRecord}
          autoPlay
        />
      );
    }
  };

  useDidUpdate(
    () => {
      if (mediaList.length < 1) return;
      setMedias(mediaList);
      updateMainMedia(mediaList[activePreviewImageIndex].element);
    },
    [mediaList],
    true,
  );

  useDidUnmount(() => {
    URL.revokeObjectURL(mainMediaPreview?.src || gemcamPreviewVideo?.element.src || "");
  });

  return (
    <div className={styles.container}>
      <div className={styles.mainMediaContainer}>{renderMainMedia()}</div>

      <div className={styles.mediaListContainer}>
        {medias.map((media, index) => {
          const isActive = index === activePreviewImageIndex;

          return (
            <div
              key={index}
              className={clsx(styles.previewMediaSmall, {
                [styles.active]: isActive,
              })}
              onClick={() => handlePreviewMediaClick(media.element, index)}
            >
              {!isActive && (
                <Button
                  className={styles.delete}
                  appearance="errorGhost"
                  onClick={(event) => handleDelete(event, media.element.src || "", index)}
                >
                  <SvgIcon icon={TrashSVG} />
                </Button>
              )}
              {media.element instanceof HTMLVideoElement && (
                <div className={styles.playIconContainer}>
                  <SvgIcon icon={PlaySVG} size={8} />
                </div>
              )}
              <img src={media.previewSrc} alt="" />

              {showLoadingRmBG && media.element.tagName === "IMG" && (
                <div className={styles.removeBgPlaceholder}>
                  <Loader size={28} type="puff-loader" color="#fff" />
                </div>
              )}
            </div>
          );
        })}
        {showLoadingPreview ||
          !mediaList ||
          (mediaList.length === 0 && (
            <div className={styles.previewMediaSmall}>
              <Loader size={24} type="circle-loader" />
            </div>
          ))}
        {!hideAddButton && mediaList.length < MAX_MEDIA_IN_PRODUCT && (
          <Button
            className={styles.addButton}
            appearance="tertiaryOutlined"
            size="unset"
            forwardRef={addButtonRef}
            onClick={setClose}
            onMouseEnter={handleOpenTooltip}
            onMouseLeave={handleCloseTooltip}
          >
            <SvgIcon className={styles.plusIcon} icon={PlusSVG} disableAutoColor />
          </Button>
        )}
      </div>
      <Tooltip
        appearance="secondary"
        position="bottom"
        target={addButtonRef}
        onClose={tooltipBoolean.setValue}
        isOpen={tooltipBoolean.value}
        withAngle
      >
        {localeStore.t('camera["camera-preview-sidebar"]["image-preview"].tooltip')}
      </Tooltip>
    </div>
  );
};
