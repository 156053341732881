import React, { useMemo, useState, useRef, useEffect } from "react";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  parsePhoneNumber,
  useDidUpdate,
  useBlockRouting,
  clsx,
  NotificationTextSecondary,
  NotificationText,
  getCountryCallingCode,
  useBoolean,
} from "@gemlightbox/core-kit";

import { DefaultRedirect } from "src/components/default-redirect";
import { useStores } from "src/hooks";
import { CompanyInfo } from "./company-info";
import { ContactSettings } from "./contact-settings";
import { BannerSettings } from "./banner-settings";
import { FORM_ID, ScrollTo } from "./share-settings.constants";
import { shareSettingsValidation } from "./share-settings.utils";
import { LogoImageType } from "../logo";
import {
  SHARE_SETTINGS_MUTATION,
  SHARE_SETTINGS_QUERY,
} from "src/api/graphql-api/share-settings/share-settings.gql";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./share-settings.module.css";
import { useMutation, useQuery } from "urql";
import {
  MediaFile,
  UserShareSettingsFormType,
  UserShareSettingsType,
} from "src/api/graphql-api/share-settings/share-settings.interface";
import { CropResultType } from "src/external-ts/components/business/crop-banner-modal";
import { useLocation } from "react-router-dom";

export const ShareSettings = observer(() => {
  const { localeStore, userStore, notificationStore } = useStores();
  const location = useLocation();
  const { onBlock, blockRouting, unblockRouting } = useBlockRouting();
  const [formInner, setFormInner] = useState<FormikProps<any> | null>(null);
  const isSubmitLoading = useBoolean(false);

  const [currentLogo, setCurrentLogo] = useState<MediaFile | string | null>(null);
  const [logoHasChanged, setLogoHasChanged] = useState(false);
  const [resetLogo, setResetLogo] = useState(false);
  const logoToSet = useRef<File | null>(null);

  const [currentBanner, setCurrentBanner] = useState<MediaFile | string | null>(null);
  const [bannerHasChanged, setBannerHasChanged] = useState(false);
  const [resetBanner, setResetBanner] = useState(false);
  const bannerToSet = useRef<File[] | null>([]);

  const [shareSettingResult, reQueryShareSetting] = useQuery<{
    userShareSettings: UserShareSettingsType;
  }>({ query: SHARE_SETTINGS_QUERY });
  const { companyLogo, bannerImages } = shareSettingResult.data?.userShareSettings || {};
  const isSubmitButtonDisabled = !formInner?.dirty && !logoHasChanged && !bannerHasChanged;
  const bannerRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  onBlock((blocker) => {
    notificationStore.open({
      title: localeStore.t('settings["share-settings"]["cancel-modal"].title'),
      message: (
        <>
          <NotificationTextSecondary>
            {localeStore.t('settings["share-settings"]["cancel-modal"].description.top')}
          </NotificationTextSecondary>
          <NotificationText>
            {localeStore.t('settings["share-settings"]["cancel-modal"].description.bottom')}
          </NotificationText>
        </>
      ),
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      confirmAppearance: "secondary",
      onOk: blocker.retry,
    });
  });

  const updateSettingSuccess = () => {
    notificationStore.open({
      title: localeStore.t('settings["share-settings"]["success-title"]'),
      confirmText: localeStore.t("common.buttons.confirm"),
      cancelText: "",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });
    reQueryShareSetting({ requestPolicy: "network-only" });
  };

  const updateSettingError = (error: any) => {
    notificationStore.open({
      title: localeStore.t('settings["share-settings"]["error-modal"].title'),
      message: `${localeStore.t('settings["share-settings"]["error-modal"].description')}: ${
        error.formattedMessage
      }`,
      confirmText: localeStore.t("common.buttons.confirm"),
      cancelText: "",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });
  };

  useEffect(() => {
    const state: { scrollTo: string } = location.state as any;
    const scrollTo = state?.scrollTo as ScrollTo;
    if (scrollTo === ScrollTo.BANNER) {
      bannerRef.current && bannerRef.current.scrollIntoView({ behavior: "smooth" });
    } else if ([ScrollTo.COMPANY_INFO, ScrollTo.COMPANY_LOGO].includes(scrollTo)) {
      companyRef.current && companyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if ([ScrollTo.CONTACT_BUTTON].includes(scrollTo)) {
      contactRef.current && contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  useDidUpdate(() => {
    setCurrentLogo(companyLogo?.small || null);
  }, [companyLogo]);

  useDidUpdate(() => {
    setCurrentBanner((bannerImages && bannerImages[0]?.small) || null);
  }, [bannerImages]);

  useDidUpdate(() => {
    if (formInner?.dirty) blockRouting();
    else unblockRouting();
  }, [formInner?.dirty]);

  const shareSettingsInitialValues: UserShareSettingsFormType = useMemo(() => {
    setBannerHasChanged(false);
    setLogoHasChanged(false);
    setCurrentLogo(companyLogo?.small || null);
    setCurrentBanner((bannerImages && bannerImages[0]?.small) || null);
    const defaultShareSetting: UserShareSettingsFormType = {
      bannerImages: undefined,
      companyLogo: undefined,
      companyName: "",
      companyWebsite: "",
      companyDescription: "",
      contactEmail: "",
      contactWhatsup: "",
      contactPhone: {
        country: "US",
        phone: "",
      },
    };
    const userShareSettings = shareSettingResult.data?.userShareSettings;
    if (userShareSettings) {
      const { contactPhone, ...filteredData } = userShareSettings || {};
      Object.assign(
        defaultShareSetting,
        Object.fromEntries(Object.entries(filteredData).filter(([key, value]) => value !== null)),
      );
      const parsedPhone = parsePhoneNumber(userShareSettings.contactPhone || "");
      defaultShareSetting.contactPhone = {
        country: parsedPhone?.country || "US",
        phone: parsedPhone?.nationalNumber || "",
      };
    }
    return defaultShareSetting;
  }, [shareSettingResult.data]);

  const handleCancel = () => {
    formInner?.resetForm();
    setResetLogo(true);
    setLogoHasChanged(false);
    setResetBanner(true);
    setBannerHasChanged(false);
  };

  const handleLogoChange = (data: LogoImageType) => {
    logoToSet.current = data.file;
    setCurrentLogo(data.b64);
    setResetLogo(false);
    setLogoHasChanged(true);
  };

  const handleBannerChange = (cropResult: CropResultType | null) => {
    if (!cropResult) {
      bannerToSet.current = null;
      setCurrentBanner("");
      setResetBanner(false);
      setBannerHasChanged(true);
      return;
    }
    bannerToSet.current = [cropResult.cropFile];
    setCurrentBanner(cropResult.cropImg);
    setResetBanner(false);
    setBannerHasChanged(true);
  };

  const [_, updateTodo] = useMutation(SHARE_SETTINGS_MUTATION);

  const handleSubmit = (values: UserShareSettingsFormType) => {
    if (isSubmitLoading.value) return;
    else isSubmitLoading.setTruthy();
    const {
      companyName,
      companyWebsite,
      companyDescription,
      contactPhone,
      contactEmail,
      contactWhatsup,
    } = values;
    const contactPhoneStr = contactPhone.phone
      ? `+${getCountryCallingCode(contactPhone.country).callingCode}${contactPhone.phone}`
      : null;
    updateTodo({
      input: {
        ...(Array.isArray(bannerToSet.current) &&
          bannerToSet.current.length && { bannerImages: bannerToSet.current }),
        ...(bannerToSet.current === null && { bannerImages: null }),
        ...(logoToSet.current && { companyLogo: logoToSet.current }),
        companyName,
        companyWebsite,
        companyDescription,
        contactEmail,
        contactWhatsup,
        contactPhone: contactPhoneStr,
      },
    })
      .then((result) => {
        if (result.data.userUpdateShareSettings.data === "ok") {
          updateSettingSuccess();
        } else {
          updateSettingError(result.error);
        }
      })
      .finally(() => {
        isSubmitLoading.setFalsy();
      });
  };

  const descriptionLength: number = formInner?.values.companyDescription.length;

  const initialLogo: MediaFile | string | null =
    typeof shareSettingsInitialValues?.companyLogo === "object"
      ? shareSettingsInitialValues.companyLogo?.small || null
      : "";

  const initialBanner: MediaFile | string | null = Array.isArray(
    shareSettingsInitialValues?.bannerImages,
  )
    ? shareSettingsInitialValues.bannerImages[0]?.small || null
    : "";

  if (userStore.isSubaccount) return <DefaultRedirect />;

  return (
    <div className={styles.container}>
      <div className={clsx(styles.content, globalStyles.addScrollStyles)}>
        <Form<UserShareSettingsFormType>
          formId={FORM_ID}
          initialValues={shareSettingsInitialValues}
          innerRef={setFormInner}
          onSubmit={handleSubmit}
          validate={shareSettingsValidation}
          enableReinitialize
        >
          <CompanyInfo
            companyRef={companyRef}
            descriptionLength={descriptionLength}
            resetLogo={resetLogo}
            initialLogo={initialLogo}
            logo={currentLogo}
            handleLogoChange={handleLogoChange}
          />
          <ContactSettings contactRef={contactRef} />
          <BannerSettings
            bannerRef={bannerRef}
            resetBanner={resetBanner}
            banner={currentBanner}
            initialBanner={initialBanner}
            onBannerChange={handleBannerChange}
          />
        </Form>
      </div>
      <div className={styles.submitButtonsContainer}>
        <Button
          appearance="secondaryOutlined"
          onClick={handleCancel}
          data-cy="share-settings-cancel-button"
        >
          {localeStore.t('settings["share-settings"].buttons.cancel')}
        </Button>
        <Button
          type="submit"
          form={FORM_ID}
          loading={isSubmitLoading.value}
          disabled={isSubmitButtonDisabled}
          data-cy="share-settings-save-button"
        >
          {localeStore.t('settings["share-settings"].buttons.save')}
        </Button>
      </div>
    </div>
  );
});

export default ShareSettings;
