import {
  Button,
  Checkbox,
  Info,
  Input,
  InputDOMElement,
  SvgIcon,
  Typography,
  useBoolean,
  useDebounce,
} from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import styles from "./password-input.module.css";
import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import { ReactComponent as SavePasswordSVG } from "src/external-ts/assets/images/share-settings/save-password.svg";
import { ReactComponent as SavePasswordDisableSVG } from "src/external-ts/assets/images/share-settings/save-password-disable.svg";
import { ReactComponent as EditPasswordSVG } from "src/external-ts/assets/images/share-settings/edit-password.svg";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/share-settings/eye.svg";
import { ReactComponent as EyeCloseSVG } from "src/external-ts/assets/images/share-settings/eye-close.svg";
import { LinkInfo } from "src/api/graphql-api/share-settings/share-settings.interface";
import { copyText, testPassword } from "../share-setting-modal.util";
import { useStores } from "src/hooks";

type StatusType = "normal" | "error";

type PasswordInputProps = {
  originPassword: string;
  originPasswordSet: boolean;
  isOldPassword: boolean;
  linkInfo: LinkInfo;
  passwordInputRef: React.RefObject<HTMLDivElement>;
  handleCheckPassword: (value: boolean, callback: (_isNeedPassword: boolean) => void) => void;
  handlePasswordBlur: (callback: VoidFunction) => void;
  handlePasswordChange: (value: string) => void;
  handleSavePassword: (callback: VoidFunction) => void;
};

enum PasswordInputStatus {
  OLD_PASS_WORD_VIEW = "OLD_PASS_WORD_VIEW",
  OLD_PASS_WORD_EDIT = "OLD_PASS_WORD_EDIT",
  PASS_WORD_VIEW = "PASS_WORD_VIEW",
  PASS_WORD_EDIT = "PASS_WORD_EDIT",
  PASS_WORD_DISABLED = "PASS_WORD_DISABLED",
}

type InputTypeValue = InputType.TEXT | InputType.PASSWORD;

enum InputType {
  TEXT = "text",
  PASSWORD = "password",
}

export const PasswordInput: React.FC<PasswordInputProps> = observer(
  ({
    originPassword,
    originPasswordSet,
    isOldPassword,
    linkInfo,
    passwordInputRef,
    handleCheckPassword,
    handlePasswordBlur,
    handlePasswordChange,
    handleSavePassword,
  }) => {
    const { localeStore } = useStores();
    const { debounce } = useDebounce(500);
    const { debounce: blurDebounce } = useDebounce(300);
    const isLoadingPasswordBoolean = useBoolean();
    const isCopiedPasswordBoolean = useBoolean();
    const isPreventBlur = useRef(false);
    const [inputType, setInputType] = useState<InputTypeValue>(
      localStorage.getItem("inputType") === InputType.TEXT ? InputType.TEXT : InputType.PASSWORD,
    );
    const { passwordSet, passwordPlain } = linkInfo;
    const [passwordInputStatus, setPasswordInputStatus] = useState(
      isOldPassword ? PasswordInputStatus.OLD_PASS_WORD_VIEW : PasswordInputStatus.PASS_WORD_VIEW,
    );

    useEffect(() => {
      passwordInputRef.current &&
        passwordInputRef.current.addEventListener("cut", handleCutPassword);

      return () => {
        passwordInputRef.current &&
          passwordInputRef.current.removeEventListener("cut", handleCutPassword);
      };
    }, []);

    useEffect(() => {
      if (inputType === InputType.TEXT) {
        localStorage.setItem("inputType", InputType.TEXT);
      } else {
        localStorage.setItem("inputType", InputType.PASSWORD);
      }
    }, [inputType, linkInfo.passwordPlain]);

    useEffect(() => {
      if (!originPasswordSet) {
        setPasswordInputStatus(PasswordInputStatus.PASS_WORD_DISABLED);
      } else {
        setPasswordInputStatus(
          isOldPassword
            ? PasswordInputStatus.OLD_PASS_WORD_VIEW
            : PasswordInputStatus.PASS_WORD_VIEW,
        );
      }
    }, [originPasswordSet, originPassword, isOldPassword]);

    useEffect(() => {
      if (!passwordSet) {
        setPasswordInputStatus(PasswordInputStatus.PASS_WORD_DISABLED);
      }
    }, [passwordSet]);

    useEffect(() => {
      if (passwordPlain === null) return;
      const isAccepted = testPassword.test(passwordPlain);
      isPasswordAccepted.setValue(isAccepted);
      if (!isAccepted) {
        if (passwordPlain.length > 64) {
          setTips(localeStore.t('components.business["share-setting-modal"].password.tips.error'));
          setStatus("error");
        } else if (passwordPlain.length >= 6 && passwordPlain.length <= 64) {
          setTips(localeStore.t('components.business["share-setting-modal"].password.tips.format'));
          setStatus("error");
        } else {
          setTips(
            localeStore.t('components.business["share-setting-modal"].password.tips.default'),
          );
          setStatus("normal");
        }
      } else {
        setTips(localeStore.t('components.business["share-setting-modal"].password.tips.default'));
        setStatus("normal");
      }
    }, [passwordPlain]);

    const handleCutPassword = (event: ClipboardEvent) => {
      if (inputType === InputType.PASSWORD) event.preventDefault();
    };

    const handleCopyPassword = () => {
      isPreventBlur.current = true;
      passwordInputRef.current?.querySelector("input")?.focus();
      copyText(passwordPlain || "", () => {
        isLoadingPasswordBoolean.setTruthy();
        isCopiedPasswordBoolean.setTruthy();
        debounce(() => {
          isLoadingPasswordBoolean.setFalsy();
        });
        const timer = setTimeout(() => {
          isCopiedPasswordBoolean.setFalsy();
          clearTimeout(timer);
        }, 3000);
      });
    };

    const handlePasswordFocus = () => {
      setPasswordInputStatus(
        isOldPassword ? PasswordInputStatus.OLD_PASS_WORD_EDIT : PasswordInputStatus.PASS_WORD_EDIT,
      );
      blurDebounce(() => {
        isPreventBlur.current = false;
      });
    };

    const handleToggleType = () => {
      isPreventBlur.current = true;
      passwordInputRef.current?.querySelector("input")?.focus();
      setInputType((prevState) =>
        prevState === InputType.TEXT ? InputType.PASSWORD : InputType.TEXT,
      );
    };

    const onPasswordBlur = () => {
      blurDebounce(() => {
        if (isPreventBlur.current) {
          isPreventBlur.current = false;
          return;
        }
        handlePasswordBlur(() => {
          setPasswordInputStatus(
            isOldPassword
              ? PasswordInputStatus.OLD_PASS_WORD_VIEW
              : PasswordInputStatus.PASS_WORD_VIEW,
          );
        });
      });
    };

    const onCheckPassword = (value: boolean) => {
      if (!value) isPreventBlur.current = true;
      handleCheckPassword(value, (_isNeedPassword: boolean) => {
        _isNeedPassword && passwordInputRef.current?.querySelector("input")?.focus();
      });
    };

    const onSavePassword = () => {
      isPreventBlur.current = true;
      if (!isPasswordAccepted.value) {
        passwordInputRef.current?.querySelector("input")?.focus();
        return;
      }
      handleSavePassword(() => {
        setPasswordInputStatus(
          isOldPassword
            ? PasswordInputStatus.OLD_PASS_WORD_VIEW
            : PasswordInputStatus.PASS_WORD_VIEW,
        );
      });
    };

    const onEditOldPassword = () => {
      passwordInputRef.current?.querySelector("input")?.focus();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === "Enter" &&
        isPasswordAccepted.value &&
        originPassword !== linkInfo.passwordPlain
      ) {
        onSavePassword();
      }
    };

    const isPasswordAccepted = useBoolean(false);
    const [tips, setTips] = useState(
      localeStore.t('components.business["share-setting-modal"].password.tips.default'),
    );
    const [status, setStatus] = useState<StatusType>("normal");

    const renderCopyButton = () => {
      return (
        <Button
          className={styles.copyButton}
          appearance="primaryGhost"
          onClick={handleCopyPassword}
          loading={isLoadingPasswordBoolean.value}
          checked={isCopiedPasswordBoolean.value}
          data-cy="copy-button"
        >
          <SvgIcon icon={CopySVG} size={24} />
        </Button>
      );
    };

    const renderEditButton = () => {
      return (
        <Button
          className={styles.editButton}
          appearance="primaryGhost"
          onClick={onEditOldPassword}
          data-cy="edit-button"
        >
          <Info
            className={styles.editPassword}
            position="bottom"
            icon={EditPasswordSVG}
            size={24}
            appearance="secondary"
            withAngle
          >
            {localeStore.t('components.business["share-setting-modal"].password.tips.edit')}
          </Info>
        </Button>
      );
    };

    const renderSaveButton = () => {
      return (
        <Button
          className={[styles.saveButton, !isPasswordAccepted.value ? styles.disabled : ""].join(
            " ",
          )}
          appearance="primaryGhost"
          onClick={onSavePassword}
          data-cy="save-button"
        >
          <SvgIcon
            icon={!isPasswordAccepted.value ? SavePasswordDisableSVG : SavePasswordSVG}
            size={28}
            color="red"
          />
        </Button>
      );
    };

    const renderEyeButton = () => {
      return (
        <Button
          className={[
            styles.eyeButton,
            passwordInputStatus === PasswordInputStatus.PASS_WORD_DISABLED ? styles.disabled : "",
          ].join(" ")}
          appearance="primaryGhost"
          disabled={passwordInputStatus === PasswordInputStatus.PASS_WORD_DISABLED}
          onClick={handleToggleType}
          data-cy="view-button"
        >
          <SvgIcon
            icon={inputType === InputType.TEXT ? EyeCloseSVG : EyeSVG}
            size={20}
            color="red"
          />
        </Button>
      );
    };

    return (
      <div className={styles.copyInputBox}>
        <Typography size="medium" color="textTertiary" className={styles.shareSettingPassword}>
          <Checkbox
            className={styles.passwordCheckBox}
            onClick={(e) => e.stopPropagation()}
            onChange={onCheckPassword}
            checked={passwordSet}
            disableError
            data-cy="media-list-item-checkbox"
            label={localeStore.t('components.business["share-setting-modal"].password.checkbox')}
          />
        </Typography>
        <div ref={passwordInputRef}>
          <Input
            id="passwordInput"
            value={
              isOldPassword && passwordInputStatus === PasswordInputStatus.OLD_PASS_WORD_VIEW
                ? "******"
                : linkInfo.passwordPlain || ""
            }
            onChange={handlePasswordChange}
            onFocus={handlePasswordFocus}
            onBlur={onPasswordBlur}
            onKeyDown={handleKeyDown}
            className={`
               ${styles.copyInput} 
               ${status === "error" ? styles.passwordError : ""} 
               ${
                 [
                   PasswordInputStatus.OLD_PASS_WORD_VIEW,
                   PasswordInputStatus.PASS_WORD_DISABLED,
                 ].includes(passwordInputStatus)
                   ? [styles.disabled, styles.viewPassword].join(" ")
                   : ""
               }
               ${
                 [PasswordInputStatus.PASS_WORD_VIEW].includes(passwordInputStatus)
                   ? styles.viewPassword
                   : ""
               }
            `}
            appearance={
              [PasswordInputStatus.OLD_PASS_WORD_EDIT, PasswordInputStatus.PASS_WORD_EDIT].includes(
                passwordInputStatus,
              )
                ? "primaryV2"
                : "primary"
            }
            placeholder={localeStore.t(
              'components.business["share-setting-modal"].password.placeholder',
            )}
            name="attribute-value"
            type={inputType}
            status={
              [
                PasswordInputStatus.OLD_PASS_WORD_VIEW,
                PasswordInputStatus.PASS_WORD_VIEW,
                PasswordInputStatus.PASS_WORD_DISABLED,
              ].includes(passwordInputStatus)
                ? "white"
                : "primary"
            }
            disableError
          >
            {([PasswordInputStatus.OLD_PASS_WORD_EDIT, PasswordInputStatus.PASS_WORD_EDIT].includes(
              passwordInputStatus,
            ) ||
              (originPassword === passwordPlain && originPassword)) &&
              renderEyeButton()}
            {originPassword === passwordPlain && originPassword && renderCopyButton()}
            {[PasswordInputStatus.OLD_PASS_WORD_VIEW].includes(passwordInputStatus) &&
              renderEditButton()}
            {[PasswordInputStatus.OLD_PASS_WORD_EDIT, PasswordInputStatus.PASS_WORD_EDIT].includes(
              passwordInputStatus,
            ) &&
              !(originPassword === passwordPlain && originPassword) &&
              renderSaveButton()}
          </Input>
        </div>
        <div
          className={[styles.passwordTip, status === "error" && styles.passwordErrorTip].join(" ")}
        >
          {tips}
        </div>
      </div>
    );
  },
);
