import { userStorage } from "src/redux/reducers/user";
import { cacheExchange, Client, fetchExchange } from "urql";

/**
 * @description use for graphql request
 */
class GraphqlClientBuilder {
  private readonly url: string;
  private readonly uniqId: (separator?: string) => string;
  private client: Client;

  constructor(url: string, uniqId: (separator?: string) => string) {
    this.url = url;
    this.uniqId = uniqId;
  }

  build() {
    if (!this.client) {
      this.client = new Client({
        url: this.url,
        exchanges: [cacheExchange, fetchExchange],
        fetchOptions: () => {
          const { token } = userStorage.get();
          return {
            headers: {
              authorization: token ? `Bearer ${token}` : "",
              "index-response": "true",
              "x-request-id": this.uniqId(),
              "x-platform": window.$platform.isApp ? "MacApp" : "Web",
            },
          };
        },
      });
    }
    return this.client;
  }
}

export default GraphqlClientBuilder;
