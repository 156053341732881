import React from "react";
import Dropzone from "react-dropzone";
import moment from "moment";

import "./index.scss";

const MAX_WIDTH_EXCEEDED = "MAX_WIDTH_EXCEEDED";
const MAX_SIZE_EXCEEDED = "MAX_SIZE_EXCEEDED";

const ImageDropzone = ({
  children,
  dropHandler,
  onError,
  shouldOpenDropzone,
  maxWidth,
  ...props
}) => {
  const dropzoneRef = React.useRef(null);

  const [image, setImage] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [progress, setProgress] = React.useState(0);

  const onDelete = React.useCallback(() => {
    setImage(null);
    setFile(null);
    setFileName("");
  }, []);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length) {
      onError({
        type: MAX_SIZE_EXCEEDED,
        message: `Please upload an image smaller than ${props.maxSize / 1000000}mb.`,
      });
    }
    acceptedFiles.forEach((item) => {
      const reader = new FileReader();

      reader.onprogress = (e) => {
        const value = Math.ceil((e.loaded * 100) / e.total);
        setProgress(value);
      };
      reader.onloadend = () => {
        const image = new Image();

        setImage(reader.result);
        image.src = reader.result;

        image.onload = () => {
          if (onError && image.width > maxWidth) {
            onDelete();
            onError({
              type: MAX_WIDTH_EXCEEDED,
              message: `Please upload an image smaller than ${maxWidth}*${maxWidth}px.`,
            });
            return;
          }

          if (dropHandler) {
            const fileName = item.name.substr(0, item.name.lastIndexOf("."));
            const fileExt = item.name.substr(item.name.lastIndexOf(".") + 1);
            const modifiedFileName = fileName + "_" + moment().toISOString() + "." + fileExt;
            setFileName(modifiedFileName);

            dropHandler({
              file: item,
              name: modifiedFileName,
              extension: fileExt,
              b64: reader.result,
            });
          }
        };
      };

      if (item) {
        setFile(item);
        reader.readAsDataURL(item);
      }
    });
  };

  React.useEffect(() => {
    if (shouldOpenDropzone && dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  }, [shouldOpenDropzone]);

  return (
    <div className="image-dropzone">
      <Dropzone onDrop={onDrop} noDragEventsBubbling ref={dropzoneRef} multiple={false} {...props}>
        {(params) => <>{children({ ...params, image, file, fileName, progress })}</>}
      </Dropzone>
    </div>
  );
};

export default ImageDropzone;
