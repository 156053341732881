import {
  ExpandableSidebar,
  ObjectType,
  clsx,
  useDidMount,
  useDidUpdate,
  useInfinityScroll,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ProductCard } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ProductRequestModel } from "src/models";
import { ExtendedProductModel } from "src/store";
import { getProductLink } from "src/utils";
import { ProductsSelectionPanel } from "./products-selection-panel";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./no-media-products-sidebar.module.css";
import { useShare } from "src/hooks/use-share.hook";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";

export type NoMediaProductsSidebarProps = {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: ObjectType;
};

export const NoMediaProductsSidebar: React.FC<NoMediaProductsSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const navigate = useNavigate();
    const { modalsStore, productsStore, localeStore } = useStores();
    const { isMobileMedia } = useMediaBreakpoints();
    const infinityScroll = useInfinityScroll<HTMLDivElement>();
    const { handleShare: handleShareProduct } = useShare();

    infinityScroll.onScroll(async () => {
      if (
        productsStore.productsWithoutMediaPage <
        Math.ceil(productsStore.productsWithoutMediaAmount / 20)
      ) {
        const resp = await productsStore.loadMoreProductsWithoutMedia();
        if (resp?.rows && resp.rows.length > 0) {
          infinityScroll.resetTrigger();
        }
      }
    });

    useDidUpdate(() => {
      infinityScroll.resetTrigger();
      infinityScroll.ref.current?.scrollTo({ top: 0 });
    }, [productsStore.productsWithoutMedia]);

    useDidMount(async () => {
      if (
        productsStore.productsWithoutMediaAmount > 0 &&
        !productsStore.productsWithoutMedia.length
      ) {
        const resp = await productsStore.loadMoreProductsWithoutMedia({ resetPage: true });
        if (resp?.rows.length === 20) {
          infinityScroll.resetTrigger();
        }
      }
    });

    const handleSelect = (product: ExtendedProductModel) => {
      product.extended.selected = !product.extended.selected;
    };

    const handleUpdate = (id: ExtendedProductModel["_id"], product: ProductRequestModel) => {
      productsStore.updateProductWithoutMedia(id, product);
    };

    const handleView = (product: ExtendedProductModel) => {
      const productLink = getProductLink(product.link.uuid);
      window.open(productLink, "_blank");
    };

    const handleShare = (product: ExtendedProductModel) => {
      handleShareProduct({
        resourceType: ResourceType.PRODUCT,
        resourceIds: [product._id],
        shareType: ShareType.SHARE_PRODUCT,
      });
    };

    const handleEdit = (product: ExtendedProductModel) => {
      navigate(`/product/${product._id}/edit`);
      setClose();
    };

    const handleDelete = (product: ExtendedProductModel) => {
      modalsStore.open("DeleteProductModal", {
        products: [product],
        deleteFunc: () => {
          productsStore.deleteProductsWithoutMedia([product]);
        },
      });
    };

    return (
      <ExpandableSidebar
        title={localeStore.t(
          'products["products-list"]["grid-view"]["products-card-without-media"].title',
        )}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
      >
        <ProductsSelectionPanel
          beforeDelete={() => {
            infinityScroll.blockTrigger();
          }}
        />
        <div
          className={clsx(styles.listContainer, globalStyles.addScrollStyles)}
          ref={infinityScroll.ref}
        >
          <div className={styles.productListWrapper}>
            {productsStore.productsWithoutMedia.map((productItem) => (
              <ProductCard
                key={productItem._id}
                className={styles.productContainer}
                titleContainerClassName={styles.productTitleContainer}
                data={productItem}
                onSelect={handleSelect}
                onUpdate={handleUpdate}
                onView={handleView}
                onShare={handleShare}
                onOpenProduct={handleEdit}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
          </div>
        </div>
      </ExpandableSidebar>
    );
  },
);
