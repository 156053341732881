import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Typography,
  Image,
  SvgIcon,
  SvgIconIconType,
  Loader,
  useDebounce,
} from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store/modals/modals.store.types";

import styles from "./view-example-modal.module.css";
import { ReactComponent as LeftSVG } from "src/external-ts/assets/images/share-settings/left.svg";
import { ReactComponent as LeftDisabledSVG } from "src/external-ts/assets/images/share-settings/left-disabled.svg";
import { ReactComponent as RightSVG } from "src/external-ts/assets/images/share-settings/right.svg";
import { ReactComponent as RightDisabledSVG } from "src/external-ts/assets/images/share-settings/right-disabled.svg";

type Sizes = 555 | 720 | 800;

export type ViewExampleModalProps = ModalExtendedType<{
  title: string;
  subTitle: string[];
  description: string[];
  images: string[];
  size: Sizes;
}>;

export const ViewExampleModal: React.FC<ViewExampleModalProps> = observer(
  ({
    isOpen,
    setClose,
    onFinalClosed,
    options: { title, subTitle, description, images, size },
  }) => {
    const { debounce } = useDebounce(300);
    const [current, setCurrent] = useState(0);
    const [imagesLoading, setImageLoading] = useState(new Array(images.length).fill(true));
    const hasPre = useMemo(() => current !== 0, [current]);
    const hasNext = useMemo(() => current !== images.length - 1, [current]);
    const sizeMap = {
      555: styles.size555,
      720: styles.size720,
      800: styles.size800,
    };
    const imageAspectRatio = {
      555: 404 / 507,
      720: 389 / 672,
      800: 389 / 752,
    };

    const pre = () => {
      if (hasPre) {
        setImageLoadingStatus(true);
        setCurrent(current - 1);
      }
    };

    const next = () => {
      if (hasNext) {
        setImageLoadingStatus(true);
        setCurrent(current + 1);
      }
    };

    const handleImagesLoading = () => {
      debounce(() => {
        setImageLoadingStatus(false);
      });
    };

    const setImageLoadingStatus = (status: boolean) => {
      const newImagesLoading = [...imagesLoading];
      newImagesLoading[current] = status;
      setImageLoading(newImagesLoading);
    };

    return (
      <Modal
        name="view-example-modal"
        data-cy="view-example-modal"
        scrollWrapperClassName={styles.modalScrollWrapper}
        contentClassName={[styles.viewContent, sizeMap[size]].join(" ")}
        isOpen={isOpen}
        withCross={true}
        setClose={setClose}
        disableBorderRadius
        onFinalClosed={onFinalClosed}
      >
        <div className={styles.viewExampleHeader}>
          <Typography className={styles.title} color="textSecondary">
            {title}
          </Typography>
        </div>
        <div className={styles.viewExampleContent}>
          <div className={styles.viewExampleDescription}>
            <Typography className={styles.subTitle} size="small600" color="textSecondary">
              {subTitle[current]}
            </Typography>
            <Typography className={styles.description} size="extraSmall" color="textTertiary">
              {description[current]}
            </Typography>
          </div>
          {images.length > 1 && (
            <div className={styles.icons}>
              <SvgIcon
                className={hasPre ? "" : styles.disabled}
                icon={hasPre ? LeftSVG : LeftDisabledSVG}
                onClick={pre}
              />
              <SvgIcon
                className={hasNext ? "" : styles.disabled}
                icon={hasNext ? RightSVG : RightDisabledSVG}
                onClick={next}
              />
            </div>
          )}
        </div>
        <div
          className={styles.exampleImageContainer}
          style={{ paddingBottom: `${imageAspectRatio[size] * 100}%` }}
        >
          {imagesLoading[current] && (
            <Loader position="absolute" withOverlay className={styles.loading} />
          )}
          <Image
            className={[
              styles.exampleImage,
              imagesLoading[current] ? styles.imageLoading : "",
            ].join(" ")}
            src={images[current]}
            loading="eager"
            onLoad={handleImagesLoading}
          />
        </div>
      </Modal>
    );
  },
);
