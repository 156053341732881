import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Heading,
  Modal,
  ObjectType,
  SelectGroup,
  SelectGroupOption,
  SvgIcon,
  Typography,
  Loader,
  useBoolean,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ProductsExportType } from "src/models";
import { ExportProductsRequestDataType } from "src/api";
import { exportOptions, optionsLabelsMap } from "./export-all-products-modal.constants";
import { pushDataLayerEvent } from "src/utils";

import styles from "./export-all-products-modal.module.css";
import { getProductPdfLink } from "src/utils/entities/product/get-product-link.utils";

export type ExportAllProductsModalProps = {
  isOpen: boolean;
  options: ObjectType;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
};

export const ExportAllProductsModal: React.FC<ExportAllProductsModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const [selectedFormat, setFormat] = useState(exportOptions[0].value);

    const { notificationStore, productsStore, toastStore, attributesStore, localeStore } =
      useStores();

    const loading = useBoolean(false);

    const handleChange = (value: ProductsExportType) => setFormat(value);

    const handleSubmit = async () => {
      // pdf export
      if (selectedFormat === ProductsExportType.pdf && options.ids) {
        window.open(getProductPdfLink(options.ids), "_blank");
        return;
      }

      loading.setTruthy();

      const payload: ExportProductsRequestDataType = {
        export_name: "gembox_export",
        product_ids: options.ids,
        format: selectedFormat,
      };

      const res = await productsStore.startExportProducts(
        payload,
        attributesStore.customAttributes,
      );

      setClose();

      if (res) {
        toastStore.open(
          <>
            <Loader className={styles.loader} type="circle-loader" size="small" />
            <Typography tag="span" size="medium600" color="textSecondary">
              {localeStore.t('products.modals["export-all-products-modal"]["loader-title"]')}{" "}
              {optionsLabelsMap[selectedFormat]}...
            </Typography>
          </>,
          {
            appearance: "primary",
            position: "bottomLeft",
            className: styles.toast,
          },
        );

        pushDataLayerEvent({ event: "export_products" });
      } else {
        notificationStore.open({
          title: localeStore.t('products.modals["export-all-products-modal"]["error-modal"].title'),
          message: localeStore.t(
            'products.modals["export-all-products-modal"]["error-modal"].message',
          ),
          confirmText: localeStore.t("common.buttons.ok"),
          cancelText: "",
          icon: "crossRoundedFilled",
          onlyConfirm: true,
        });
      }
    };

    return (
      <Modal
        data-cy="export-all-products-modal"
        contentClassName={styles.modalContent}
        name="export-all-products-modal"
        setClose={setClose}
        isOpen={isOpen}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        disablePadding
        withCross
      >
        <Heading className={styles.heading} tag="h2" color="textSecondary">
          {localeStore.t('products.modals["export-all-products-modal"].title')}
        </Heading>
        <Typography className={styles.description} size="medium" color="textTertiary">
          {localeStore.t('products.modals["export-all-products-modal"].description')}
        </Typography>
        <SelectGroup
          className={styles.select}
          inputWrapperClassName={styles.selectInput}
          label={localeStore.t(
            'products.modals["export-all-products-modal"]["select-field"].label',
          )}
          placeholder={localeStore.t(
            'products.modals["export-all-products-modal"]["select-field"].placeholder',
          )}
          searchValue={optionsLabelsMap[selectedFormat]}
          value={selectedFormat}
          onChange={handleChange}
          disableUncheck
          disableSearch
          disableClear
          data-name={selectedFormat}
        >
          {exportOptions.map((option) => {
            return (
              <SelectGroupOption
                data-name={option.value}
                className={styles.selectOption}
                contentContainerClassName={styles.selectOptionContent}
                value={option.value}
                key={option.value}
              >
                <SvgIcon icon={option.icon}></SvgIcon>
                {option.label}
              </SelectGroupOption>
            );
          })}
        </SelectGroup>
        <div className={styles.buttons}>
          <Button data-cy="cancel-export-all-btn" appearance="tertiaryOutlined" onClick={setClose}>
            {localeStore.t("common.buttons.cancel")}
          </Button>
          <Button data-cy="export-all-btn" onClick={handleSubmit} loading={loading.value}>
            {localeStore.t("common.buttons.export")}
          </Button>
        </div>
      </Modal>
    );
  },
);
