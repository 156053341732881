import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Button,
  SvgIcon,
  Collapse,
  ActionOption,
  useBoolean,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { downloadMedias } from "src/utils";
import { MoreButtonActions, ProUserUpgradeModal } from "src/external-ts/components";
import { RemoveBGButton } from "./remove-bg-button";
import { DustRemoveButton } from "./dust-remove-button";
import { AIRetouchButton } from "./ai-retouch-button";

import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as AssignSVG } from "src/external-ts/assets/images/media/media-item-actions/assign-grey.svg";
import { ReactComponent as DownloadSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./media-selection-panel.module.css";
import { useShare } from "src/hooks/use-share.hook";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";

export const MediaSelectionPanel: React.FC = observer(() => {
  const { mediaStore, modalsStore, localeStore, notificationStore } = useStores();
  const selectedAmount = mediaStore.selectedMediaAmount;
  const [proUserUpgradeContent, setProUserUpgradeContent] = useState("");
  const proUserUpgradeBool = useBoolean(false);
  const { handleShare: handleShareMedia } = useShare();

  const handleSelectAll = () => mediaStore.selectAllMediaList("selected");

  const handleUnSelectAll = () => mediaStore.unselectAllMediaList("selected");

  const handleShare = async () => {
    const isSelectOne = mediaStore.isSingleMediaSelected;
    handleShareMedia({
      resourceType: ResourceType.MEDIA,
      resourceIds: isSelectOne
        ? [mediaStore.selectedMediaList[0].id]
        : mediaStore.selectedMediaList.map((media) => media.id),
      shareType: isSelectOne ? ShareType.SHARE_MEDIA : ShareType.SHARE_MEDIAS,
    });
  };

  const handleAssign = () => {
    modalsStore.open("AssignToProductSidebar", {
      medias: mediaStore.selectedMediaList,
    });
  };

  const handleDownload = () => {
    downloadMedias(mediaStore.selectedMediaList);
    handleUnSelectAll();
  };

  const handleDelete = () => {
    modalsStore.open("DeleteMediaModal", { media: mediaStore.selectedMediaList });
  };

  return (
    <Collapse
      className={styles.mediaSelectionPanelContainer}
      isOpen={mediaStore.isMediaSelected}
      opacityTransition
    >
      <div className={styles.mediaSelectionPanelWrapper} data-cy="media-selection-panel">
        <div className={styles.mediaSelectionPanelLeft}>
          <div className={styles.selectedAmount}>
            <Typography size="small600">
              {selectedAmount}&nbsp;
              {localeStore.t('media["media-selection-panel"]["selected-amount"].media')}
            </Typography>
            &nbsp;
            <Typography size="small">
              {localeStore.t('media["media-selection-panel"]["selected-amount"].selected')}
            </Typography>
          </div>
          <Button appearance="secondary" onClick={handleUnSelectAll} data-cy="unselect-button">
            {localeStore.t('media["media-selection-panel"].buttons.unselect')}
          </Button>
          <Button appearance="primaryOutlined" onClick={handleSelectAll} data-cy="select-button">
            {localeStore.t('media["media-selection-panel"].buttons.select')}
          </Button>
        </div>

        <div className={styles.mediaSelectionPanelRight}>
          <Button
            appearance="primaryGhost"
            onClick={handleShare}
            data-cy="media-panel-action-share"
            disabled={mediaStore.isPendingUploedMediaSelected}
          >
            <SvgIcon icon={ShareSVG} />
            {localeStore.t('media["media-selection-panel"].buttons.share')}
          </Button>

          <RemoveBGButton visible={proUserUpgradeBool} contentSet={setProUserUpgradeContent} />

          <AIRetouchButton visible={proUserUpgradeBool} contentSet={setProUserUpgradeContent} />

          <DustRemoveButton visible={proUserUpgradeBool} contentSet={setProUserUpgradeContent} />

          <MoreButtonActions>
            <ActionOption onClick={handleAssign} data-cy="assign-to-sku">
              <SvgIcon icon={AssignSVG} />
              {localeStore.t('media["media-selection-panel"]["panel-actions"].assign')}
            </ActionOption>
            <ActionOption
              onClick={handleDownload}
              data-cy="download-media"
              disabled={mediaStore.isPendingUploedMediaSelected}
            >
              <SvgIcon icon={DownloadSVG} />
              {localeStore.t("common.buttons.download")}
            </ActionOption>
            <ActionOption onClick={handleDelete} data-cy="delete-selected-media">
              <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
              {localeStore.t("common.buttons.delete")}
            </ActionOption>
          </MoreButtonActions>
        </div>

        <ProUserUpgradeModal
          visible={proUserUpgradeBool}
          onClose={proUserUpgradeBool.setFalsy}
          content={proUserUpgradeContent}
        ></ProUserUpgradeModal>
      </div>
    </Collapse>
  );
});
