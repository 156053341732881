import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  EllipseActions,
  ActionOption,
  Checkbox,
  Input,
  SvgIcon,
  clsx,
} from "@gemlightbox/core-kit";

import { ExtendedProductModel } from "src/store/products";
import { getMediaPreviewFromProduct, proFeatureTooltip } from "src/utils";
import { PendingUploadStatus, ProductRequestModel } from "src/models";
import { useStores } from "src/hooks";
import { useLimits } from "../../../../containers/settings/subscriptions/subscriptions.utils";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { ReactComponent as ARBlinkIcon } from "src/external-ts/assets/images/media/blink-type.svg";
import { ReactComponent as NoMediaSVG } from "src/external-ts/assets/images/media/add-media-grey.svg";
import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/eye-grey.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as GeneratePostSVG } from "src/external-ts/assets/images/fountain-pen-grey.svg";
import { ReactComponent as SocialsSVG } from "src/external-ts/assets/images/socials-icons.svg";
import { ReactComponent as ProBadgeSVG } from "src/external-ts/assets/images/badges/pro-badge-yellow.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as DownloadCloudSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";

import styles from "./product-card.module.css";

export type ProductCardProps = {
  data: ExtendedProductModel;
  className?: string;
  titleContainerClassName?: string;
  showImageCounter?: boolean;
  selectOnContainerClick?: boolean;
  showPrefixIcon?: boolean;
  showMediaAmount?: boolean;
  style?: React.CSSProperties;
  onSelect?: (item: ExtendedProductModel) => void;
  onUpdate?: (id: ExtendedProductModel["_id"], product: ProductRequestModel) => void;
  onView?: (product: ExtendedProductModel) => void;
  onDownload?: (product: ExtendedProductModel) => void;
  onShare?: (product: ExtendedProductModel) => void;
  onOpenProduct?: (product: ExtendedProductModel) => void;
  onEdit?: (product: ExtendedProductModel) => void;
  onDelete?: (product: ExtendedProductModel) => void;
};

export const ProductCard: React.FC<ProductCardProps> = observer(
  ({
    data,
    className = "",
    titleContainerClassName = "",
    showImageCounter = false,
    selectOnContainerClick = false,
    showPrefixIcon = false,
    onSelect,
    onUpdate,
    onView,
    onDownload,
    onShare,
    onOpenProduct,
    onEdit,
    onDelete,
    style,
  }) => {
    const {
      productsStore,
      attributesStore,
      localeStore,
      userStore,
      modalsStore,
      subscriptionsStore,
    } = useStores();

    const { canGeneratePosts } = useLimits();

    const editableTitle = !userStore.isCTFSub;

    const [productTitle, setProductTitle] = useState<string>(data.title);
    const [isEditActive, setIsInputActive] = useState(false);

    const handleStartEdit = () => setIsInputActive(true);

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter" || !onUpdate) return;

      const target = e.target as HTMLInputElement;

      //if no title or the title is the same - don't update
      if (!productTitle || productTitle === data.title) {
        target?.blur();
        return;
      }

      const newProduct = { ...data, title: productTitle };
      const newProductPayload = productsStore.getProductRequestPayload(
        newProduct,
        attributesStore.attributes,
      );

      await onUpdate(newProduct._id, newProductPayload);
      target.blur();
    };

    const handleBlur = () => {
      setProductTitle(data.title);
      setIsInputActive(false);
    };

    // const handleGeneratePost = () => {
    //   modalsStore.open("ShareModal", { type: "product", data: data, tab: "generatePost" });
    // };

    const handlePointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (subscriptionsStore.isProSubscription) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleSelect = () => onSelect?.(data);

    const isProductAR = data.images.some((image) => image.type === "blink");

    const previewImageSrc = data.images?.length ? getMediaPreviewFromProduct(data.images?.[0]) : "";
    const isPdf = data?.images?.[0]?.type === "pdf";
    const isUploading =
      data.images &&
      data.images.findIndex((media) => media?.status === PendingUploadStatus.pending) >= 0;

    const canShowActions = onView || onDownload || onShare || onEdit || onDelete;

    const isDownloadButtonDisabled = !data.images.length;

    return (
      <div
        className={clsx(styles.productCard, className)}
        onClick={() => (selectOnContainerClick ? onSelect?.(data) : undefined)}
        data-name={data.title}
        style={style}
        data-cy="product-card"
      >
        <div className={styles.productCardActions}>
          {onSelect && (
            <Checkbox
              className={styles.checkbox}
              checked={data.extended.selected}
              onClick={(e) => e.stopPropagation()}
              onChange={handleSelect}
              disableError
              data-cy="product-card-checkbox"
            />
          )}

          {canShowActions && (
            <EllipseActions
              tooltipClassName={styles.actionsTooltipContainer}
              appearance="quaternary"
              size="tiny"
              data-cy="product-card-actions"
            >
              {onView && (
                <ActionOption onClick={() => onView(data)} data-cy="table-action-view">
                  <SvgIcon icon={EyeSVG} />
                  {localeStore.t("common.buttons.view")}
                </ActionOption>
              )}
              {onDownload && (
                <ActionOption
                  onClick={() => onDownload(data)}
                  data-cy="table-action-download"
                  disabled={isDownloadButtonDisabled}
                >
                  <SvgIcon icon={DownloadCloudSVG} />
                  {localeStore.t("common.buttons.download")}
                </ActionOption>
              )}
              {onShare && (
                <ActionOption onClick={() => onShare(data)} data-cy="table-action-share">
                  <SvgIcon icon={ShareSVG} />
                  {localeStore.t("common.buttons.share")}
                </ActionOption>
              )}
              {onEdit && (
                <ActionOption onClick={() => onEdit(data)} data-cy="table-action-edit">
                  <SvgIcon icon={EditSVG} />
                  {localeStore.t("common.buttons.edit")}
                </ActionOption>
              )}
              {/* <ActionOption
                onClick={handleGeneratePost}
                data-cy="product-card-generate-post"
                className={styles.generatePostAction}
                disabled={!canGeneratePosts}
                onPointerEnter={handlePointerEnter}
              >
                <SvgIcon icon={GeneratePostSVG} />
                {localeStore.t(
                  'media["media-list"]["media-item"]["media-actions"]["generate-ai-post"]',
                )}
                <SvgIcon icon={SocialsSVG} className={styles.socialsIcons} />
                <SvgIcon icon={ProBadgeSVG} className={styles.proBadge} />
              </ActionOption> */}
              {onDelete && (
                <ActionOption onClick={() => onDelete(data)} data-cy="table-action-delete">
                  <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
                  {localeStore.t("common.buttons.delete")}
                </ActionOption>
              )}
            </EllipseActions>
          )}
        </div>

        <div className={styles.productCardImageContainer} onClick={() => onOpenProduct?.(data)}>
          <div className={styles.views}>
            <SvgIcon icon={EyeSVG} size={16} />
            {data.views}
          </div>
          <div className={clsx(styles.imageFiller, "productCardImageFiller")} />
          <div className={styles.imageWrapper}>
            {previewImageSrc && !isPdf && (
              <img className={styles.productCardImage} src={previewImageSrc} alt="" />
            )}

            {!isUploading && previewImageSrc && isPdf && <SvgIcon icon={PdfSVG} size={80} />}

            {!previewImageSrc && isUploading && (
              <div className={styles.pendingUpWrapper}>
                <SvgIcon icon={PendingUpSVG} size={65} />
              </div>
            )}

            {!isUploading && !previewImageSrc && <SvgIcon icon={NoMediaSVG} size={[83, 53]} />}
            {showImageCounter && data.images.length > 1 && (
              <div className={styles.imageCounter}>{data.images.length}</div>
            )}
          </div>
        </div>
        <div
          className={clsx(styles.productTitleContainer, titleContainerClassName)}
          onClick={handleStartEdit}
        >
          {showPrefixIcon && isProductAR && <SvgIcon icon={ARBlinkIcon} />}
          {editableTitle && isEditActive ? (
            <Input
              className={styles.productTitleInputContainer}
              inputWrapperClassName={styles.productTitleInputWrapper}
              inputClassName={styles.productTitleInputField}
              affixClassName={styles.productTitleSuffix}
              appearance="primaryV2"
              value={productTitle}
              onChange={setProductTitle}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              disableError
              autoFocus
            />
          ) : (
            <div className={styles.productTitle}>
              <span className={globalStyles.applySingleOverflow}>{data.title}</span>
              {editableTitle && <span className={styles.editButton}>Edit</span>}
            </div>
          )}
        </div>
      </div>
    );
  },
);
