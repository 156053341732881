import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Button,
  ExpandableSidebar,
  Loader,
  useFilters,
  useFetch,
  useDidUpdate,
  useInfinityScroll,
  Nullable,
  Tabs,
} from "@gemlightbox/core-kit";

import { getMedias } from "src/api";
import { useStores } from "src/hooks";
import { MediaModel, MediaType, ProductImageModel } from "src/models";
import { ExtendedMediaModel } from "src/store";
import { checkForMedia } from "src/utils";
import {
  MAX_BLINK_MEDIA_IN_PRODUCT,
  MAX_MEDIA_IN_PRODUCT,
} from "src/containers/products/products.constants";
import { MediaItem } from "src/containers/media/media-list/media-item";
import { MEDIA_TYPE_OPTIONS_NEW } from "src/containers/media/media.constants";
import { MaxBlinkAssignedToast } from "./components/max-blink-assigned-toast";
import { MaxMediaAssignedToast } from "./components/max-media-assigned-toast";

import styles from "./product-assign-media-sidebar.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { useShare } from "src/hooks/use-share.hook";

export interface ProductAssignMediasSidebarProps {
  isOpen: boolean;
  options: {
    productImages: ProductImageModel[];
    onSubmit: (selectedProducts: MediaModel[]) => void;
    onlyOneMedia?: boolean;
  };
  setClose: (value: false) => void;
  onFinalClosed?: VoidFunction;
}

export const ProductAssignMediasSidebar: React.FC<ProductAssignMediasSidebarProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }) => {
    const { modalsStore, localeStore } = useStores();
    const { handleShare: handleShareMedia } = useShare();

    const { productImages, onSubmit, onlyOneMedia = false } = options;

    const MEDIA_TYPE_OPTIONS_NO_BLINK = useMemo(
      () =>
        MEDIA_TYPE_OPTIONS_NEW.filter((option) => option.value !== "blink").map((option) => {
          option.label = localeStore.t(
            `media["media-control-panel"].tabs["${option.value}"]` as LocaleCodeTypes,
          );
          return option;
        }),
      [],
    );

    const [selectedMedia, setSelectedMedia] = useState<MediaModel[]>([]);

    const [medias, setMedias] = useState<MediaModel[]>([]);
    const [maxMediaToastOpened, setMaxMediaToastOpened] = useState(false);
    const [maxBlinkToastOpened, setMaxBlinkToastOpened] = useState(false);

    const { filters, queryString, setValue, setValues, handleResetFilters } = useFilters({
      isStateBased: true,
      initialState: {
        page: 1,
        limit: 50,
        type: onlyOneMedia ? "image" : "all",
      },
      omitQueryValues: { type: "all" },
    });

    const useInfinityScrollHook = useInfinityScroll<HTMLDivElement>();
    useInfinityScrollHook.onScroll(() => {
      setValue("page")(filters.page + 1);
    });
    const { loading, onFetchSuccess, refresh } = useFetch(
      getMedias.setQueryParams("?withoutSku=true&" + queryString.slice(1)),
      {
        dependencies: [queryString],
      },
    );
    onFetchSuccess(({ rows, filtered_items }) => {
      const { page, limit } = filters;
      const offset = page * limit;
      if (offset < filtered_items) useInfinityScrollHook.resetTrigger();

      const selectedIds = productImages.map(({ id }) => id);
      rows = rows.filter(({ sku, id }) => !sku && !selectedIds.includes(id));

      setMedias((prev) => {
        const diff = rows.filter((item) => !prev.find((prevItem) => prevItem.id === item.id));
        return prev.concat(diff);
      });
    });

    const getProductARBlinkMediaList = (productMedia: Array<MediaModel | ProductImageModel>) => {
      return !productMedia.length
        ? []
        : productMedia.filter(({ type }) => type === MediaType.blink);
    };

    const checkIfMaxBlinkMediaAmountExceeded = () => {
      const selectedBlinks = getProductARBlinkMediaList(selectedMedia);
      const existingBlinks = getProductARBlinkMediaList(productImages);
      return existingBlinks.length + selectedBlinks.length >= MAX_BLINK_MEDIA_IN_PRODUCT;
    };

    const checkIfMaxMediaAmountExceeded = () => {
      const selectedLength = selectedMedia.length;
      const productLength = productImages.length;
      return selectedLength + productLength >= MAX_MEDIA_IN_PRODUCT;
    };

    useDidUpdate(() => {
      const prevBlinks = getProductARBlinkMediaList(productImages);
      const presentBlinks = getProductARBlinkMediaList(selectedMedia);
      const isMaxMediaLimitExceeded = checkIfMaxMediaAmountExceeded();

      if (isMaxMediaLimitExceeded) {
        setMaxMediaToastOpened(true);
        setTimeout(() => setMaxMediaToastOpened(false), 2000);
      }

      if (presentBlinks.length > prevBlinks.length && !isMaxMediaLimitExceeded) {
        setMaxBlinkToastOpened(true);
        setTimeout(() => setMaxBlinkToastOpened(false), 2000);
      }
    }, [selectedMedia.length]);

    const handleClose = () => setClose(false);

    const handleItemClick = (item: MediaModel) => {
      if (onlyOneMedia) {
        setSelectedMedia([item]);
        return;
      }

      const isAlreadySelected = checkForMedia(item.id, selectedMedia);
      let result = [...selectedMedia];

      if (isAlreadySelected) {
        result = result.filter(({ id }) => id !== item.id);
      } else {
        result.push(item);
      }

      setSelectedMedia(result);
    };

    const checkIfMediaIsDisabled = (item: MediaModel) => {
      let result = checkIfMaxMediaAmountExceeded();

      if (result) return result;

      if (item.type === MediaType.blink) {
        result = checkIfMaxBlinkMediaAmountExceeded();
      }

      return result;
    };

    const handleChangeType = (option: Nullable<string>) => {
      const value = option;
      if (!value) return;
      setMedias([]);
      useInfinityScrollHook.blockTrigger();
      setValues({
        ...filters,
        type: value as any,
        page: 1,
      });
    };

    const handleSubmit = () => {
      handleClose();
      onSubmit(selectedMedia);
    };

    const handleUploadSuccess = () => {
      setMedias([]);
      handleResetFilters(false);
      refresh();
    };

    const handleUploadFromPC = () =>
      modalsStore.open("UploadMediaModal", {
        onSuccess: handleUploadSuccess,
      });

    return (
      <ExpandableSidebar
        title={localeStore.t('["product-assign-media-sidebar"].title')}
        icon="cross"
        iconPos="outside"
        data-cy="product-assign-media-sidebar"
        sidebarContentClassName={styles.content}
        sidebarHeaderClassName={styles.header}
        sidebarFooterClassName={styles.footer}
        header={
          <Button
            data-cy="media-assign-upload"
            className={styles.uploadButton}
            onClick={handleUploadFromPC}
          >
            {localeStore.t('["product-assign-media-sidebar"].buttons.upload')}
          </Button>
        }
        footer={
          <>
            <Button
              data-cy="assign-media-cancel"
              appearance="secondaryOutlined"
              onClick={handleClose}
            >
              {localeStore.t('["product-assign-media-sidebar"].buttons.cancel')}
            </Button>
            <Button
              data-cy="assign-media-confirm"
              appearance="secondary"
              onClick={handleSubmit}
              disabled={!selectedMedia.length}
            >
              {localeStore.t('["product-assign-media-sidebar"].buttons.add')}
            </Button>
          </>
        }
        sidebarContentInnerRef={useInfinityScrollHook.ref}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
        isOpen={isOpen}
      >
        <div className={styles.contentHeaderWrapper}>
          {!onlyOneMedia && (
            <Tabs
              tabs={MEDIA_TYPE_OPTIONS_NO_BLINK}
              currentTab={filters.type}
              onChange={handleChangeType}
            />
          )}
          {!!selectedMedia.length && !onlyOneMedia && (
            <div className={styles.selectedWrapper} data-cy="selected-wrapper">
              <div>
                <Typography size="small600" color="textSecondary">
                  {selectedMedia.length}
                  &nbsp;
                  {localeStore.t('["product-assign-media-sidebar"].select.media')}
                </Typography>
                <Typography size="small" color="textSecondary">
                  &nbsp;{localeStore.t('["product-assign-media-sidebar"].select.selected')}
                </Typography>
              </div>
              <Typography
                className={styles.warningContainer}
                size="extraSmall"
                color="textTertiary"
              >
                {localeStore.t('["product-assign-media-sidebar"].select.warning.top')} &nbsp;
                <Typography size="extraSmall500" color="textSecondary">
                  {localeStore.t(
                    '["product-assign-media-sidebar"].select.warning["middle-try-on-link"]',
                  )}
                </Typography>
                &nbsp; {localeStore.t('["product-assign-media-sidebar"].select.warning.bottom')}
              </Typography>
            </div>
          )}
          {loading && <Loader className={styles.loaderWrapper} size={24} />}
          <MaxBlinkAssignedToast
            isOpened={maxBlinkToastOpened}
            onClose={() => setMaxBlinkToastOpened(false)}
          />
          <MaxMediaAssignedToast
            isOpened={maxMediaToastOpened}
            onClose={() => setMaxMediaToastOpened(false)}
          />
        </div>
        <div className={styles.mediasWrapper}>
          {medias.map((data) => (
            <MediaItem
              key={data.id}
              className={styles.mediaItem}
              item={data as ExtendedMediaModel}
              selected={checkForMedia(data.id, selectedMedia)}
              onSelect={handleItemClick}
              disabled={checkIfMediaIsDisabled(data)}
              disableActions
              selectOnClick
              handleShareMedia={handleShareMedia}
            />
          ))}
        </div>
      </ExpandableSidebar>
    );
  },
);
