import { Button, Modal, SvgIcon, useDidMount, useCall, SelectOption } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import { useStores } from "src/hooks";
import { ModalExtendedType } from "src/store";
import { config } from "src/config/environment";
import { useState } from "react";
import { postProduct } from "src/api";
import { ReactComponent as GeneratedTipSVG } from "src/external-ts/assets/images/camera/generated-sku-tip-icon.svg";
import { ReactComponent as GeneratedTip2SVG } from "src/external-ts/assets/images/camera/generated-sku-tip2-icon.svg";

import styles from "./generated-sku-modal.module.css";

export type GeneratedSkuModalProps = ModalExtendedType<{
  productType?: string;
  onSave: (value: SelectOption<number>) => void;
  onEdit: () => void;
}>;

export const GeneratedSkuModal: React.FC<GeneratedSkuModalProps> = observer(
  ({ isOpen, options, onFinalClosed, setClose }) => {
    const { productType, onSave, onEdit } = options;
    const { localeStore, userStore, productsStore } = useStores();
    const [sku, setSku] = useState("");
    const [creating, setCreating] = useState(false);
    const createProductCall = useCall(postProduct);
    createProductCall.onCallSuccess(async ({ rows, total_items }) => {
      productsStore.sendCreateProductEvent(total_items);
      const product = rows[0];

      if (!sku) return;
      onSave({ label: sku, value: product._id });
      setClose();
    });

    const handleCloseModal = () => {
      setClose();
      onEdit();
    };

    const handleSaveModal = () => {
      createProductCall.submit({ data: { title: sku } });
    };

    useDidMount(() => {
      setCreating(true);
      fetch(config.graphqlURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userStore.token}`,
        },
        body: JSON.stringify({
          query: `
            mutation {
              productGenSku(productType: "${productType || "Unknown"}") {
                data
              }
            }
          `,
        }),
      }).then((res) => {
        res.json().then((result) => {
          setCreating(false);
          setSku(result?.data?.productGenSku?.data);
        });
      });
    });

    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollContent}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        disableBackdropClose
        setClose={handleCloseModal}
        onFinalClosed={onFinalClosed}
        name="generated-sku-modal"
        data-cy="generated-sku-modal"
      >
        <div className={styles.title}>
          <span>{localeStore.t('camera["generated-sku-modal"].title')}:</span>
        </div>
        <div className={styles.sku}>{sku || "TT-NNNN"}</div>
        <div className={styles.descriptionContainer}>
          <div className={styles.description}>
            <SvgIcon icon={GeneratedTipSVG} size={20} />
            <span>{localeStore.t('camera["generated-sku-modal"].description')}</span>
          </div>
          <div className={styles.description}>
            <SvgIcon icon={GeneratedTip2SVG} size={20} />
            <span>{localeStore.t('camera["generated-sku-modal"].description2')}</span>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            appearance="secondaryOutlined"
            className={styles.itemBtn}
            onClick={handleCloseModal}
          >
            {localeStore.t('camera["generated-sku-modal"].edit')}
          </Button>
          <Button loading={creating} className={styles.itemBtn} onClick={handleSaveModal}>
            {localeStore.t('camera["generated-sku-modal"].save')}
          </Button>
        </div>
      </Modal>
    );
  },
);

export default GeneratedSkuModal;
