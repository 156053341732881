import React from "react";
import { observer } from "mobx-react-lite";
import { FormField, Heading, Typography, Image } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ViewExample } from "../view-example/index";

import WhatsappImg from "src/external-ts/assets/images/share-settings/whatsapp.png";
import PhoneImg from "src/external-ts/assets/images/share-settings/phone.png";
import MailImg from "src/external-ts/assets/images/share-settings/mail.png";
import ContactInfoStepOne from "src/external-ts/assets/images/share-settings/contact-info-step-1.jpg";
import ContactInfoStepTwo from "src/external-ts/assets/images/share-settings/contact-info-step-2.jpg";
import ContactInfoSetpThree from "src/external-ts/assets/images/share-settings/contact-info-step-3.jpg";
import styles from "./contact-settings.module.css";

interface ContactSettingsProps {
  contactRef: React.RefObject<HTMLDivElement>;
}

export const ContactSettings: React.FC<ContactSettingsProps> = observer(({ contactRef }) => {
  const { localeStore, modalsStore } = useStores();

  const openContactInfoModal = () => {
    modalsStore.open("ViewExampleModal", {
      size: 800,
      title: `${localeStore.t('settings["share-settings"].link')}`,
      subTitle: [
        `${localeStore.t(
          'settings["share-settings"]["contact-settings"]["example-info"].titles.0',
        )}`,
        `${localeStore.t(
          'settings["share-settings"]["contact-settings"]["example-info"].titles.1',
        )}`,
        `${localeStore.t(
          'settings["share-settings"]["contact-settings"]["example-info"].titles.2',
        )}`,
      ],
      description: [
        `${localeStore.t(
          'settings["share-settings"]["contact-settings"]["example-info"].descriptions.0',
        )}`,
        `${localeStore.t(
          'settings["share-settings"]["contact-settings"]["example-info"].descriptions.1',
        )}`,
        `${localeStore.t(
          'settings["share-settings"]["contact-settings"]["example-info"].descriptions.2',
        )}`,
      ],
      images: [ContactInfoStepOne, ContactInfoStepTwo, ContactInfoSetpThree],
    });
  };

  return (
    <div ref={contactRef} className={styles.container} data-cy="contact-settings">
      <Heading className={styles.title} tag="h2" color="textSecondary">
        {localeStore.t('settings["share-settings"]["contact-settings"].title')}
      </Heading>
      <div className={styles.descriptionContainer} data-cy="contact-settings-text-section">
        <Typography className={styles.subTitle} size="small600" color="textSecondary">
          {localeStore.t('settings["share-settings"]["contact-settings"].subtitle')}
        </Typography>
        <Typography className={styles.description} size="extraSmall" color="textTertiary">
          {localeStore.t('settings["share-settings"]["contact-settings"].description')}{" "}
          <ViewExample callback={openContactInfoModal} />
        </Typography>
      </div>
      <div className={styles.fieldContainer}>
        <Image src={MailImg} size={44} />
        <FormField
          className={styles.emailContainer}
          inputWrapperClassName={styles.emailWrapper}
          appearance="primaryV2"
          type="text"
          placeholder={localeStore.t(
            'settings["share-settings"]["contact-settings"].fields.email.placeholder',
          )}
          label={localeStore.t('settings["share-settings"]["contact-settings"].fields.email.label')}
          name="contactEmail"
        />
      </div>
      <div className={styles.fieldContainer}>
        <Image src={WhatsappImg} size={44} />
        <FormField
          className={styles.whatsappContainer}
          inputWrapperClassName={styles.whatsappFieldWrapper}
          name="contactWhatsup"
          appearance="primaryV2"
          label="WhatsApp"
          placeholder={localeStore.t(
            'settings["share-settings"]["contact-settings"].fields.whatsapp.placeholder',
          )}
          type="number"
          data-cy="contact-settings-whatsapp"
        />
      </div>

      <div className={styles.fieldContainer}>
        <Image src={PhoneImg} size={44} />
        <FormField
          containerClassName={styles.phoneContainer}
          phoneFieldClassName={styles.phone}
          countrySelectClassName={styles.countryCode}
          type="phone"
          placeholder={localeStore.t(
            'settings["share-settings"]["contact-settings"].fields.phone.placeholder',
          )}
          label={localeStore.t('settings["share-settings"]["contact-settings"].fields.phone.label')}
          name="contactPhone"
        />
      </div>
    </div>
  );
});
