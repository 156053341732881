import { LINK_RESOLVE, LINK_UPDATE } from "src/api/graphql-api/share-settings/share-settings.gql";
import {
  LinkInfo,
  LinkResolveReq,
  LinkUpdateReq,
} from "src/api/graphql-api/share-settings/share-settings.interface";
import { useMutation } from "urql";
import { useStores } from "./use-stores.hook";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";
import { useCallback } from "react";

export type ShareInfoType = LinkResolveReq & {
  shareType: ShareType;
};

/**
 * @description Ensure this is mounted only on the root.
 */
export const useShare = () => {
  const { modalsStore } = useStores();
  let handleShare = ({ resourceType, resourceIds, shareType }: ShareInfoType) => {
    console.error("handle share error");
  };
  try {
    const [, linkResolve] = useMutation<{ linkResolve: LinkInfo }, LinkResolveReq>(LINK_RESOLVE);
    const [, linkUpdate] = useMutation<{ id: number; input: LinkUpdateReq }>(LINK_UPDATE);

    handleShare = useCallback(
      async ({ resourceType, resourceIds, shareType }: ShareInfoType) => {
        modalsStore.open("ShareSettingModal", {
          shareType,
          onLoad: async () => {
            try {
              const result = await linkResolve({ resourceType, resourceIds });
              const { error, data } = result;

              if (error) {
                console.error("linkResolve error", error);
                return;
              }

              if (!data?.linkResolve) {
                console.error("linkResolve is empty", error);
                return;
              }
              return data.linkResolve;
            } catch (error) {
              console.error("handleShare error", error);
            }
          },
          onSubmit: (id: number, linkUpdateReq: LinkUpdateReq) => {
            linkUpdate({ id, input: linkUpdateReq });
          },
        });
      },
      [linkResolve, linkUpdate, modalsStore],
    );
  } catch (error) {
    console.log("useShare error");
  } finally {
    return { handleShare };
  }
};
