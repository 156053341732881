import React, { useMemo, useState } from "react";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Button,
  Form,
  FormField,
  ExpandableSidebar,
  useCall,
  Nullable,
  isEmpty,
  clsx,
  globalStyles,
} from "@gemlightbox/core-kit";

import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { getUserTagManagerInfoCallback, postAIGenerateDescriptions } from "src/api";
import { pushDataLayerEvent } from "src/utils";
import { useStores } from "src/hooks";
import { ErrorCodes } from "src/constants";
import { ProductType, productDefaultTypesNames } from "src/models";
import { GeneratedDescription } from "./generated-description";
import {
  FORM_ID,
  aiDescriptionBuilderInitialForm,
  maxTags,
  maxTagLength,
  productTypeOptions,
} from "./ai-description-builder-sidebar.constants";
import {
  AIDescriptionBuilderSidebarProps,
  AIDescriptionBuilderForm,
} from "./ai-description-builder-sidebar.types";
import { aiDescriptionBuilderValidation } from "./ai-description-builder-sidebar.utils";

import styles from "./ai-description-builder-sidebar.module.css";

export const AIDescriptionBuilderSidebar: React.FC<AIDescriptionBuilderSidebarProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }: AIDescriptionBuilderSidebarProps) => {
    const {
      initialTitle = "",
      initialProductType = "",
      initialDescriptions = null,
      onDescriptionSelect,
    } = options;

    const { notificationStore, localeStore, userStore } = useStores();

    const [generatedDescriptions, setGeneratedDescriptions] =
      useState<Nullable<string[]>>(initialDescriptions);
    const [selectedDescription, setSelectedDescription] = useState(-1);

    const [formInner, setFormInner] = useState<FormikProps<AIDescriptionBuilderForm> | null>(null);

    const hasValidDescription = selectedDescription >= 0;

    const handleShowError = () => {
      notificationStore.open({
        title: localeStore.t(
          'components.business["ai-description-builder-sidebar"]["warning-modal"].title',
        ),
        message: localeStore.t(
          'components.business["ai-description-builder-sidebar"]["warning-modal"].message',
        ),
        confirmText: localeStore.t(
          'components.business["ai-description-builder-sidebar"]["warning-modal"]["ok-text"]',
        ),
        cancelText: "",
        icon: "exclamation",
        onlyConfirm: true,
      });
    };

    const { submit, onCallSuccess, onCallError, onCallFinal } = useCall(postAIGenerateDescriptions);
    onCallSuccess((payload) => {
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "gemhub:product:ai_description:received",
          user_id: response.user_id,
          account_type: response.account_type,
          is_trial: response.isTrial,
        });
      });

      const filtered = payload.filter((opt) => {
        return Boolean(opt) && opt !== "This is a product description generator.";
      });
      if (isEmpty(filtered)) return handleShowError();
      setGeneratedDescriptions(filtered);
    });
    onCallError(({ originalError }) => {
      const { code, message } = originalError;

      if (code === ErrorCodes.PRODUCT_MAX_GPT) {
        return notificationStore.open({
          title: localeStore.t(
            'components.business["ai-description-builder-sidebar"]["error-modal"].title',
          ),
          confirmText: localeStore.t(
            'components.business["ai-description-builder-sidebar"]["error-modal"]["ok-text"]',
          ),
          cancelText: "",
          message,
          onlyConfirm: true,
        });
      }

      handleShowError();
    });
    onCallFinal(() => notificationStore.closeLoader());

    const initialValues = useMemo(() => {
      const initialValues = { ...aiDescriptionBuilderInitialForm };

      if (initialTitle) initialValues.detailedTitle = initialTitle;

      if (initialProductType) {
        const foundValue = productTypeOptions.find(
          (opt) => opt.value === initialProductType,
        )?.value;
        if (foundValue) initialValues.productType = foundValue.toString();
      }

      return initialValues;
    }, []);

    const handleCloseSidebar = () => {
      notificationStore.open({
        title: localeStore.t(
          'components.business["ai-description-builder-sidebar"]["cancel-modal"].title',
        ),
        message: localeStore.t(
          'components.business["ai-description-builder-sidebar"]["cancel-modal"].message',
        ),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: localeStore.t("common.buttons.cancel"),
        onOk: setClose,
      });
    };

    const handleGoBack = () => setGeneratedDescriptions(null);

    const handleEditDescription = (text: string, index: number) => {
      if (!generatedDescriptions) return;
      const descriptions = generatedDescriptions.slice();
      descriptions[index] = text;
      setGeneratedDescriptions(descriptions);
    };

    const handleSubmitDescription = (e: React.MouseEvent) => {
      const values = formInner?.values;
      if (!values || !hasValidDescription || !generatedDescriptions) return;

      e.preventDefault();

      onDescriptionSelect({
        detailedTitle: values.detailedTitle,
        productType: values.productType,
        description: generatedDescriptions[selectedDescription],
      });
      setClose();
    };

    const handleSubmit = async (values: AIDescriptionBuilderForm) => {
      const title = values.detailedTitle;
      let productType = values.productType;
      // NOTE: if watches -> pass it as bracelets due to AI not trained for watches.
      productType = productType === ProductType.watches ? ProductType.bracelets : productType;

      const tags = values.tags;

      notificationStore.openLoader({
        loaderType: "circle-loader",
      });
      submit({ data: { title, tags, productType, language: userStore.aiLanguage } });
    };

    return (
      <ExpandableSidebar
        sidebarFooterClassName={styles.footer}
        iconPos="outside"
        title={localeStore.t('components.business["ai-description-builder-sidebar"].title')}
        icon="cross"
        data-cy="ai-description-builder-sidebar"
        footer={
          <>
            <Button
              data-cy="ai-description-cancel-button"
              type="button"
              appearance="tertiaryOutlined"
              onClick={generatedDescriptions ? handleGoBack : handleCloseSidebar}
            >
              {generatedDescriptions
                ? localeStore.t(
                    'components.business["ai-description-builder-sidebar"].buttons.back',
                  )
                : localeStore.t(
                    'components.business["ai-description-builder-sidebar"].buttons.cancel',
                  )}
            </Button>
            <Button
              data-cy="ai-description-confirm-button"
              type="submit"
              form={FORM_ID}
              onClick={handleSubmitDescription}
              disabled={generatedDescriptions ? !hasValidDescription : !formInner?.isValid}
            >
              {generatedDescriptions
                ? localeStore.t(
                    'components.business["ai-description-builder-sidebar"].buttons.done',
                  )
                : localeStore.t(
                    'components.business["ai-description-builder-sidebar"].buttons.next',
                  )}
            </Button>
          </>
        }
        setClose={handleCloseSidebar}
        onFinalClosed={onFinalClosed}
        isOpen={isOpen}
      >
        <Form
          className={clsx({ [globalStyles.deleteElement]: generatedDescriptions })}
          formId={FORM_ID}
          initialValues={initialValues}
          innerRef={setFormInner}
          onSubmit={handleSubmit}
          validate={aiDescriptionBuilderValidation}
          validateOnMount
        >
          <Typography className={styles.title} size="extraSmall" color="textTertiary">
            {localeStore.t('components.business["ai-description-builder-sidebar"].subtitle')}
          </Typography>
          <FormField
            data-cy="ai-description-title-input"
            appearance="primaryV2"
            type="text"
            name="detailedTitle"
            labelMessage={localeStore.t(
              'components.business["ai-description-builder-sidebar"]["product-name-field"]["label-message"]',
            )}
            label={localeStore.t(
              'components.business["ai-description-builder-sidebar"]["product-name-field"].label',
            )}
            placeholder={localeStore.t(
              'components.business["ai-description-builder-sidebar"]["product-name-field"].placeholder',
            )}
            required
          />
          <FormField
            data-cy="ai-description-type-select"
            appearance="primaryV2"
            type="select"
            name="productType"
            label={localeStore.t(
              'products["products-list"]["products-table"]["products-table-header"].producttype',
            )}
            placeholder={`${localeStore.t(
              '["product-attributes"]["attribute-field"]["select-placeholder"]',
            )} ${localeStore.t(
              'products["products-list"]["products-table"]["products-table-header"].producttype',
            )}`}
            options={productTypeOptions.map((item) => {
              const label = localeStore.t(
                `create["augmented-reality-item"]["ar-types"].${
                  productDefaultTypesNames[item.label.toLocaleLowerCase()]
                }` as LocaleCodeTypes,
                item.label,
              );
              return { ...item, label };
            })}
            disableSingleOptionUncheck
            disableClearing
            required
          />
          <FormField
            data-cy="ai-description-tags-input"
            type="tags-input"
            appearance="primaryV2"
            chipAppearance="primaryV2"
            className={styles.select}
            label={localeStore.t(
              'components.business["ai-description-builder-sidebar"]["tags-label"]',
            )}
            placeholder={localeStore.t(
              'components.business["ai-description-builder-sidebar"].placeholder',
            )}
            name="tags"
            maxTags={maxTags}
            maxLength={maxTagLength}
            required
          />
        </Form>
        {generatedDescriptions && (
          <div className={styles.descriptionsWrapper}>
            <Typography className={styles.title} size="extraSmall" color="textTertiary">
              {localeStore.t(
                'components.business["ai-description-builder-sidebar"]["description-generated-subtitle"].first',
              )}{" "}
              {generatedDescriptions.length}{" "}
              {localeStore.t(
                'components.business["ai-description-builder-sidebar"]["description-generated-subtitle"].last',
              )}
            </Typography>
            {generatedDescriptions.map((title, i) => (
              <GeneratedDescription
                data-cy="ai-generated-description"
                key={i}
                title={title}
                index={i}
                selectedDescription={selectedDescription}
                onCheck={setSelectedDescription}
                onEdit={handleEditDescription}
              />
            ))}
          </div>
        )}
      </ExpandableSidebar>
    );
  },
);

export default AIDescriptionBuilderSidebar;
