import { getCountryCallingCode, isPossiblePhoneNumber } from "@gemlightbox/core-kit";

import { validateEmail } from "src/utils";
import {
  UserShareSettingsErrorType,
  UserShareSettingsFormType,
} from "src/api/graphql-api/share-settings/share-settings.interface";

export const shareSettingsValidation = (values: UserShareSettingsFormType) => {
  const errors: UserShareSettingsErrorType = {};

  const contactCallingCode = getCountryCallingCode(values.contactPhone.country).callingCode;
  const contactInternationalPhone = `+${contactCallingCode}${values.contactPhone.phone}`;

  if (!isPossiblePhoneNumber(contactInternationalPhone) && values.contactPhone.phone !== "") {
    errors.contactPhone = "Phone number is not correct";
  }

  if (!validateEmail(values.contactEmail) && values.contactEmail !== "") {
    errors.contactEmail = "Email is not correct";
  }

  return errors;
};
