import React from "react";
import { observer } from "mobx-react-lite";
import { useWindowSize, SvgIcon, isEmpty, clsx } from "@gemlightbox/core-kit";

import ImageDropzone from "src/components/ImageDropzone";
import profileImg from "src/containers/settings/profile/images/profile-picture-placeholder.svg";
import { BREAKPOINTS } from "src/constants";
import { useStores } from "src/hooks";
import { GlobalImageDataModel } from "src/models";
import { LogoImageType } from "./logo.types";

import cameraImg from "src/common/images/camera.svg";
import { ReactComponent as CameraIcon } from "src/common/images/camera-gray.svg";
import { ReactComponent as PlusIcon } from "src/external-ts/assets/images/plus-thick-grey.svg";
import "./index.scss";

export type DropImageError = {
  type: "MAX_WIDTH_EXCEEDED" | "MAX_SIZE_EXCEEDED";
  message: string;
};

type LogoProps = {
  initialLogo: Omit<GlobalImageDataModel, "createdAt"> | string | null;
  title?: string;
  containerClassName?: string;
  detailsClassName?: string;
  disabled?: boolean;
  accept?: string;
  tip?: string;
  appearance?: "primary" | "primaryV2";
  onImageChange?: (data: LogoImageType) => void;
  onImageError?: (error: DropImageError) => void;
};

export const Logo: React.FC<LogoProps> = observer(
  ({
    title,
    onImageChange,
    onImageError,
    initialLogo,
    disabled,
    accept = "image/png, image/jpg, image/jpeg, image/gif",
    tip,
    appearance = "primary",
    containerClassName = "",
    detailsClassName = "",
  }) => {
    const { windowWidth } = useWindowSize();
    const { localeStore } = useStores();

    const getPicture = (image?: string) => {
      let result;

      if (typeof initialLogo === "object") {
        if (windowWidth >= BREAKPOINTS.tablet) {
          result = initialLogo?.original || initialLogo?.medium || initialLogo?.small || profileImg;
        } else {
          result = initialLogo?.small || initialLogo?.medium || initialLogo?.original || profileImg;
        }
      } else {
        result = initialLogo;
      }
      if (image) result = image;

      return result;
    };

    const containerStyles = clsx(
      appearance === "primary" ? "gallery-logo" : "company-logo",
      containerClassName,
    );

    const detailsStyles = clsx(
      appearance === "primary" ? "gallery-logo__details" : "company-logo-details",
      detailsClassName,
    );

    return (
      <div className={containerStyles} data-cy="gallery-logo-container">
        <div className="gallery-logo__logo" data-cy="upload-avatar-button">
          {disabled ? (
            <div className="gallery-logo-container">
              <div className="image">
                <img src={getPicture()} alt="" />
              </div>
            </div>
          ) : (
            <ImageDropzone
              dropHandler={onImageChange}
              maxSize={10000000}
              maxWidth={1000}
              accept={accept}
              onError={onImageError}
              {...({} as any)}
            >
              {({ getRootProps, getInputProps, image }: any) => (
                <>
                  {appearance === "primary" ? (
                    <div className="gallery-logo-container">
                      <div className="image">
                        <img src={getPicture(image)} alt="" />

                        <div className="edit-logo" {...getRootProps()}>
                          <img src={cameraImg} alt="" />
                          <input {...getInputProps()} data-cy="company-logo-input" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="company-logo-container" {...getRootProps()}>
                      {!isEmpty(initialLogo) ? (
                        <>
                          <img className="company-logo-image" src={getPicture()} alt="" />
                          <div className="company-logo-camera-icon">
                            <SvgIcon icon={CameraIcon} />
                          </div>
                        </>
                      ) : (
                        <SvgIcon className="company-logo-icon" icon={PlusIcon} />
                      )}
                      <input
                        {...getInputProps()}
                        name="company_logo"
                        data-cy="company-logo-input"
                      />
                    </div>
                  )}
                </>
              )}
            </ImageDropzone>
          )}
        </div>

        <div className={detailsStyles}>
          {title && <div className="headline"> {title} </div>}
          {!disabled && (
            <div className="tip">
              <div>{localeStore.t("settings.logo.tip.top")}</div>
              <div>{localeStore.t("settings.logo.tip.middle")}</div>
              <div>{tip || localeStore.t("settings.logo.tip.bottom")}</div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default Logo;
