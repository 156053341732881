/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import styles from "./index.module.css";
import { getShopifyTaskRecords } from "src/api/shopify/shopify.api";
import _ from "lodash";
import { SvgIcon } from "@gemlightbox/core-kit";
import { ReactComponent as ArrowDownSVG } from "src/external-ts/assets/images/arrow-down-grey.svg";
import { ReactComponent as SuccessTipSVG } from "src/external-ts/assets/images/success-tip.svg";
import { ReactComponent as ErrorTipSVG } from "src/external-ts/assets/images/error-tip.svg";

import { useStores } from "src/hooks";

const SyncProductError = (props) => {
  const { taskId, totalItems, errorCount } = props;
  const [page, setPage] = React.useState(1);
  const [pageSize] = React.useState(5);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [productErrorItems, setProductErrorItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { localeStore } = useStores();
  const productErrorWrapperRef = useRef(null);

  const onLoadData = async () => {
    try {
      setLoading(true);
      const result = await getShopifyTaskRecords
        .getRequest({
          queryParams: `?page=${page}&pageSize=${pageSize}&taskId=${taskId}&status=error`,
        })
        .fetch();

      if (result.success) {
        setProductErrorItems((previewItem) =>
          _.uniqBy(previewItem.concat(result.success.items), "id"),
        );
        setHasNextPage(result.success.hasNextPage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onLoadData();
  }, [page, pageSize]);

  useEffect(() => {
    if (!loading && productErrorWrapperRef.current) {
      productErrorWrapperRef.current.scrollTop = productErrorWrapperRef.current.scrollHeight;
    }
  }, [productErrorItems, loading]);

  const renderBottom = () => {
    if (!hasNextPage) {
      return null;
    }
    if (loading) {
      return (
        <div className={styles.loadingTip}>
          {localeStore.t(
            'settings.integrations.modals["integration-errors-modal"].report["loading-tip"]',
          )}
        </div>
      );
    }

    return (
      <div
        onClick={() => {
          setPage(page + 1);
        }}
        className={styles.showMoreBtnWrapper}
      >
        <div className={styles.showMoreBtnText}>
          {localeStore.t(
            'settings.integrations.modals["integration-errors-modal"].report["show-more"]',
          )}
        </div>
        <SvgIcon className={styles.moreBtnSVG} icon={ArrowDownSVG} />
      </div>
    );
  };

  return (
    <div className={styles.productErrorsList}>
      <div className={styles.tipWrapper}>
        <div className={`${styles.tip} ${styles.sucessTipColor}`}>
          <SvgIcon className={styles.tipSvg} icon={SuccessTipSVG} />
          <div className={styles.tipText}>
            {totalItems - errorCount}{" "}
            {localeStore.t(
              'settings.integrations.modals["integration-errors-modal"].report["success-tip"]',
            )}
          </div>
        </div>
        <div className={`${styles.tip} ${styles.errorTipColor}`}>
          <SvgIcon className={styles.tipSvg} icon={ErrorTipSVG} />
          <div className={styles.tipText}>
            {errorCount}{" "}
            {localeStore.t(
              'settings.integrations.modals["integration-errors-modal"].report["failed-tip"]',
            )}
          </div>
        </div>
      </div>

      <div className={styles.productErrorsListTitle}>
        {localeStore.t(
          'settings.integrations.modals["integration-errors-modal"].report["error-sub-title"]',
        )}
      </div>

      <div className={styles.productErrorWrapper} ref={productErrorWrapperRef}>
        {productErrorItems.map((item) => {
          return (
            <div key={item.id} className={styles.productItem}>
              <img
                className={styles.productItemImage}
                src={item?.metaInfo?.mediaInfo[0]?.file?.small}
                alt=""
              />
              <div className={styles.productItemInfo}>
                <div className={styles.productItemInfoTitle}>
                  {item?.metaInfo?.product?.product.title}
                </div>
                <div className={styles.productItemInfoError}>{item?.errorInfo?.message}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.paddingAll5}>{renderBottom()}</div>
    </div>
  );
};

export default SyncProductError;
