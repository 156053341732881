import { Button, Modal, SvgIcon, clsx } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import { useStores } from "src/hooks";
import { ReactComponent as GeneratedTipSVG } from "src/external-ts/assets/images/camera/generated-sku-tip-icon.svg";
import { ReactComponent as GeneratedTip2SVG } from "src/external-ts/assets/images/camera/generated-sku-tip2-icon.svg";
import { ReactComponent as MagnifierSVG } from "src/external-ts/assets/images/camera/magnifier-icon.svg";
import { ReactComponent as BoxSVG } from "src/external-ts/assets/images/camera/box-icon.svg";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-icon.svg";
import GeneratedSkuTutorialBannerJPG from "src/external-ts/assets/images/camera/generated-sku-tutorial-banner.jpg";
import styles from "./generated-sku-tutorial-modal.module.css";
import { ModalExtendedType } from "src/store";

export type GeneratedSkuTutorialModalProps = ModalExtendedType<{ onFinalOpened?: () => void }>;

export const GeneratedSkuTutorialModal: React.FC<GeneratedSkuTutorialModalProps> = observer(
  ({ isOpen, setClose, options }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollContent}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalOpened={options?.onFinalOpened}
        disableBackdropClose
        name="generated-sku-tutorial-modal"
        data-cy="generated-sku-tutorial-modal"
      >
        <img className={styles.banner} src={GeneratedSkuTutorialBannerJPG} />
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <span className={styles.titleBig}>
              {localeStore.t('camera["generated-sku-tutorial-modal"]["title-big"]')}
            </span>
            <div className={styles.descriptionContainer}>
              <div className={styles.description}>
                <SvgIcon icon={GeneratedTipSVG} size={20} />
                <span>
                  {localeStore.t('camera["generated-sku-tutorial-modal"]["description-big"]')}
                </span>
              </div>
              <div className={styles.description}>
                <SvgIcon icon={GeneratedTip2SVG} size={20} />
                <span>
                  {localeStore.t('camera["generated-sku-tutorial-modal"]["description2-big"]')}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.titleContainer}>
            <span className={styles.titleSmall}>
              {localeStore.t('camera["generated-sku-tutorial-modal"]["title-small"]')}
            </span>
            <div className={styles.descriptionContainer}>
              <div className={clsx(styles.description, styles.small)}>
                <SvgIcon icon={MagnifierSVG} size={20} />
                <span>
                  {localeStore.t('camera["generated-sku-tutorial-modal"]["description-small"]')}
                </span>
              </div>
              <div className={clsx(styles.description, styles.small)}>
                <SvgIcon icon={BoxSVG} size={20} />
                <span>
                  {localeStore.t('camera["generated-sku-tutorial-modal"]["description2-small"]')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.btn} onClick={setClose}>
            {localeStore.t('camera["generated-sku-tutorial-modal"].ok')}
          </Button>
        </div>
        <Button
          className={styles.cross}
          appearance="secondaryGhost"
          onClick={setClose}
          data-cy="cross-btn"
        >
          <SvgIcon color="#fff" icon={CrossSVG} size={32} />
        </Button>
      </Modal>
    );
  },
);

export default GeneratedSkuTutorialModal;
