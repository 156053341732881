import { dashboardApiMiddleware } from "src/api";
import {
  DeleteShopifySubscriptionRequestData,
  GetShopifySettingsResponseData,
  GetShopifyTaskRecordsResponseData,
  PostShopifySubscriptionRequestData,
  PostShopifySubscriptionResponseData,
  PutActiveShopRequestData,
  PutActiveShopResponseData,
} from "./shopify.interface";

export const getShopifySettings = dashboardApiMiddleware<GetShopifySettingsResponseData[]>()({
  method: "get",
  endpoint: "/api/shopify/settings",
});

export const putActiveShop = dashboardApiMiddleware<
  PutActiveShopResponseData,
  PutActiveShopRequestData
>()({
  method: "put",
  endpoint: "/api/shopify/set_shop",
});

export const postShopifySubscription = dashboardApiMiddleware<
  PostShopifySubscriptionResponseData,
  PostShopifySubscriptionRequestData
>()({
  method: "post",
  endpoint: "/api/shopify/subscription",
});

export const putShopifySubscriptionAdditional =
  dashboardApiMiddleware<PostShopifySubscriptionResponseData>()({
    method: "put",
    endpoint: "/api/shopify/subscription/additional",
  });

export const deleteShopifySubscription = dashboardApiMiddleware<
  undefined,
  DeleteShopifySubscriptionRequestData
>()({
  method: "delete",
  endpoint: "/api/shopify/subscription",
});

export const getShopifyTaskRecords = dashboardApiMiddleware<GetShopifyTaskRecordsResponseData>()({
  method: "get",
  endpoint: "/api/shopify/tasks/records",
});
