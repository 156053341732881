import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  useDidMount,
  clsx,
  RefModel,
  curry,
  handleSetRef,
  useVirtualizedLoading,
} from "@gemlightbox/core-kit";

import { ProductCard } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ExtendedProductModel } from "src/store/products";
import { ProductRequestModel } from "src/models";
import { getProductLink, downloadVideo } from "src/utils";
import { MediaWithoutSKUCard } from "./media-without-sku-card";
import { ProductsCardWithoutMedia } from "./products-card-without-media";
import { NUMBER_OF_MEDIA_TO_PREVIEW } from "../media-preview";
import {
  productCardMinWidth,
  productCardAddedHeight,
  productCardHeightMultiplier,
} from "../../products.constants";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./grid-view.module.css";
import { ProductsPendingUploadCard } from "./products-pending-upload-card";
import { useShare } from "src/hooks/use-share.hook";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";

export type GridViewProps = {
  productsContainerRef: RefModel<HTMLDivElement>;
};

export const GridView: React.FC<GridViewProps> = observer(({ productsContainerRef }) => {
  const navigate = useNavigate();

  const { productsStore, mediaStore, modalsStore, localeStore } = useStores();

  const { handleShare: handleShareProduct } = useShare();

  const shouldRenderNoSKUCard = mediaStore.resultsMediaAmount > 0;
  const shouldRenderNoMediaCard =
    productsStore.productsWithoutMediaAmount > 0 &&
    productsStore.filters.isThereMedia === undefined;
  const shouldRenderPendingUploadCard = productsStore.totalProductsPendingUploadAmount > 0;

  let offset = 0;
  offset += +shouldRenderNoSKUCard;
  const renderNoSKUCardIndex = offset - 1;
  offset += +shouldRenderNoMediaCard;
  const renderNoMediaCardIndex = offset - 1;
  offset += +shouldRenderPendingUploadCard;
  const renderPendingUploadCardIndex = offset - 1;

  const itemsAmount = productsStore.productsList.length + offset;

  const { virtualizedItems, virtualizingContainerRef, virtualizingContentRef, onScroll } =
    useVirtualizedLoading({
      itemsAmount: itemsAmount,
      minItemWidth: productCardMinWidth,
      addedHeight: productCardAddedHeight,
      heightMultiplier: productCardHeightMultiplier,
    });

  useDidMount(() => {
    mediaStore.loadMediaList({ limit: NUMBER_OF_MEDIA_TO_PREVIEW, withoutSku: true });
    productsStore.loadProductsPendingUploadList();
  });

  const handleSelect = (product: ExtendedProductModel) => {
    productsStore.toggleProductsList(product, "selected");
  };

  const handleUpdate = (id: ExtendedProductModel["_id"], product: ProductRequestModel) => {
    productsStore.updateProduct(id, product);
  };

  const handleView = (product: ExtendedProductModel) => {
    const productLink = getProductLink(product.link.uuid);
    window.open(productLink, "_blank");
  };

  const handleDownload = (product: ExtendedProductModel) => {
    const isVideo = product.images[0].type === "video" || product.images[0].type === "video360";
    if (isVideo && product.images.length === 1) {
      downloadVideo(product.images[0].id, product as ExtendedProductModel);
      return;
    }
    modalsStore.open("DownloadMediaModal", { media: [product], type: "products" });
  };

  const handleShare = (product: ExtendedProductModel) => {
    handleShareProduct({
      resourceType: ResourceType.PRODUCT,
      resourceIds: [product._id],
      shareType: ShareType.SHARE_PRODUCT,
    });
  };

  // const handleOpenProduct = (product: ExtendedProductModel) => {
  //   navigate(`/product/${product._id}`);
  // };

  const handleEdit = (product: ExtendedProductModel) => {
    navigate(`/product/${product._id}/edit`);
  };

  const handleDelete = (product: ExtendedProductModel) => {
    modalsStore.open("DeleteProductModal", { products: [product] });
  };

  useEffect(() => {
    if (productsStore.scrollRestoration) {
      virtualizingContainerRef.current?.scrollTo(productsStore.scrollPosition);
      productsStore.setScrollRestoration(false);
      productsStore.setScrollPosition({ left: 0, top: 0 });
    } else {
      productsStore.setScrollPosition({ left: 0, top: 0 });
    }
  }, []);

  return (
    <div
      className={clsx(styles.productListContainer, globalStyles.addScrollStyles)}
      ref={curry(handleSetRef)([virtualizingContainerRef, productsContainerRef])}
      onScroll={(e) => {
        productsStore.setScrollPosition({
          left: (e.target as HTMLDivElement).scrollLeft,
          top: (e.target as HTMLDivElement).scrollTop,
        });
        onScroll();
      }}
      data-cy="products-grid-view"
    >
      <div className={styles.productListWrapper} ref={curry(handleSetRef)(virtualizingContentRef)}>
        {virtualizedItems.map(({ index, styles: inlineStyles }) => {
          if (shouldRenderNoSKUCard && index === renderNoSKUCardIndex) {
            return <MediaWithoutSKUCard key="no-sku-card" style={inlineStyles} />;
          }
          if (shouldRenderNoMediaCard && index === renderNoMediaCardIndex) {
            return <ProductsCardWithoutMedia key="no-media-card" style={inlineStyles} />;
          }

          if (shouldRenderPendingUploadCard && index === renderPendingUploadCardIndex) {
            return <ProductsPendingUploadCard key="pending-upload-card" style={inlineStyles} />;
          }

          const productItem = productsStore.productsList[index - offset];
          if (!productItem) return null;

          return (
            <ProductCard
              key={productItem._id}
              className={styles.productContainer}
              titleContainerClassName={styles.productTitleContainer}
              style={inlineStyles}
              data={productItem}
              onSelect={handleSelect}
              onUpdate={handleUpdate}
              onView={handleView}
              onDownload={handleDownload}
              onShare={handleShare}
              onOpenProduct={handleEdit}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          );
        })}
      </div>
    </div>
  );
});
