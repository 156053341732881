import React, { useRef } from "react";

import styles from "./upload-tooltip.module.css";

export type UploadToolTipProps = {
  children: React.ReactNode;
};

export const UploadToolTip: React.FC<UploadToolTipProps> = ({ children }) => {
  return <div className={styles.notice}>{children}</div>;
};
