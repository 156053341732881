import { ApiBuilder } from "@gemlightbox/core-kit";
import { config } from "src/config/environment";
import { LocaleModel } from "src/models";

const isLocal = window.location.hostname.includes("localhost");

const api = new ApiBuilder({
  baseUrl: isLocal ? window.location.origin : config.staticURL,
}).build();

export const getTranslationFile = api<LocaleModel>()({
  method: "get",
  endpoint: `/${isLocal ? "" : "dashboard_dist/"}locales/:locale?t=${
    import.meta.env.I18N_TIMESTAMP
  }`,
  requestType: "text",
  responseType: "json",
});
