import React, { useMemo, useRef } from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import {
  ApiRequest,
  ExtractApiResponse,
  Nullable,
  SelectOption,
  useDidMount,
} from "@gemlightbox/core-kit";

import { getUserTagManagerInfoCallback, postAIGenerateImageDescriptions } from "src/api";
import { orderDefaultAttributes, pushDataLayerEvent } from "src/utils";
import { useStores } from "src/hooks";
import { AttributeType, AttributeModel, ProductImageModel } from "src/models";
import WSConnection from "src/common/socket";
import { AttributeField } from "./attribute-field";

import styles from "./product-attributes.module.css";

export type ProductAttributesProps = {
  productMedias: ProductImageModel[];
};

export const ProductAttributes: React.FC<ProductAttributesProps> = observer(
  ({ productMedias }: ProductAttributesProps) => {
    const { attributesStore, modalsStore, notificationStore, userStore } = useStores();

    const requestRef =
      useRef<Nullable<ApiRequest<ExtractApiResponse<typeof postAIGenerateImageDescriptions>>>>(
        null,
      );
    const requestIdRef = useRef("");

    const attributes = useMemo(() => {
      // toJS is required to make copy and convert Proxies to objects
      return [
        ...orderDefaultAttributes(toJS(attributesStore.defaultAttributes)),
        ...toJS(attributesStore.customAttributes),
      ];
    }, [attributesStore.loading]);

    const { values, initialValues, setFieldValue } = useFormikContext<any>();

    const handleOpenAIDescription = (initialDescriptions?: string[]) => {
      modalsStore.open("AIDescriptionBuilderSidebar", {
        initialTitle: values.detailedTitle,
        initialProductType: values.productType,
        initialDescriptions,
        onDescriptionSelect: ({ productType, description, detailedTitle }) => {
          setFieldValue("description", description);
          if (detailedTitle) setFieldValue("detailedTitle", detailedTitle);
          if (productType) setFieldValue("productType", productType);
        },
      });
    };

    const handleOpenAIDescriptionClick = async () => {
      const foundMedia = productMedias.find((media) => {
        return media.type === "image";
      });

      if (!foundMedia) return handleOpenAIDescription();

      notificationStore.openLoader({
        loaderType: "progress-circle",
        contentClassName: styles.loader,
        progress: 0,
      });

      const formData = new FormData();
      formData.append("media_ids", foundMedia.id.toString());
      formData.append("use_gpt", "true");
      formData.append("language", userStore.aiLanguage);

      const request = postAIGenerateImageDescriptions.getRequest({
        data: formData,
      });
      requestRef.current = request;

      request.events.on("requestStart", ({ headers }) => {
        requestIdRef.current = headers["x-request-id"];
      });

      const requestResult = await request.fetch();

      if (requestResult.status === "cancelled") return;
      if (requestResult.error) return handleOpenAIDescription();
      if (requestResult.success) notificationStore.closeLoader();

      userStore.loadUserMeSilently();

      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "gemhub:product:ai_description:received",
          user_id: response.user_id,
          account_type: response.account_type,
          is_trial: response.isTrial,
        });
      });

      handleOpenAIDescription([
        requestResult.success.gpt_description_1,
        requestResult.success.gpt_description_2,
        requestResult.success.gpt_description_3,
      ]);
    };

    const handleOnDeleteAttributeOption = (attribute: AttributeModel) => {
      let valueToSet;

      if (attribute.type === AttributeType.multiselect) {
        const selectValue = values[attribute.name] || [];
        valueToSet = selectValue.filter((value: string) => attribute.values?.includes(value));
      } else {
        const selectValue = values[attribute.name] || "";
        valueToSet = attribute.values?.includes(selectValue) ? selectValue : "";
      }

      setFieldValue(attribute.name, valueToSet);
    };

    const handleProductTypeOptionChange = (option: SelectOption | undefined) => {
      setFieldValue("productType", option ? option.value : "");
    };

    useDidMount(() => {
      const userId = userStore.userMe?.user._id;

      if (!userId) return;

      const progressWS = new WSConnection(`/user/${userId}`, userStore.token);

      progressWS.on("progress/UPDATE", ({ payload }: any) => {
        if (requestIdRef.current === payload.requestID) {
          notificationStore.openLoader({
            loaderType: "progress-circle",
            contentClassName: styles.loader,
            progress: payload.progress,
          });

          if (payload.progress === 100) notificationStore.closeLoader();
        }
      });

      return () => {
        progressWS.destroy();
      };
    });

    return (
      <>
        {attributes.map((attribute) => (
          <AttributeField
            key={attribute.id}
            initialValue={initialValues[attribute.name]}
            attribute={attribute}
            onDeleteAttributeOption={handleOnDeleteAttributeOption}
            onProductTypeOptionChange={handleProductTypeOptionChange}
            onGenerateDescription={handleOpenAIDescriptionClick}
            data-cy={`product-${attribute.name}-attribute`}
          />
        ))}
      </>
    );
  },
);

export default ProductAttributes;
