import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";

import styles from "./view-example.module.css";

interface ViewExampleProps {
  callback: VoidFunction;
}

export const ViewExample: React.FC<ViewExampleProps> = observer(({ callback }) => {
  const { localeStore } = useStores();

  return (
    <span className={styles.viewExample} onClick={callback}>
      {localeStore.t('settings["share-settings"].link')}
    </span>
  );
});
