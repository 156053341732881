import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Descendant } from "slate";
import {
  TableCell,
  Checkbox,
  useDidUpdate,
  TableRow,
  TableTitleSection,
  Typography,
  LastCell,
} from "@gemlightbox/core-kit";

import { PendingUploadStatus, ProductRequestModel } from "src/models";
import { TableActionIcon } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { getAttributesForRequest, getMediaPreviewFromProduct, getProductLink } from "src/utils";
import { ExtendedProductModel } from "src/store/products";
import { getAttributesValues } from "../../products-table.utils";
import { MediaPreview } from "./media-preview";
import { Attribute } from "./attribute";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";

import { ReactComponent as PencilSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./products-table-row.module.css";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";
import { useShare } from "src/hooks/use-share.hook";

export interface ProductsTableRowProps {
  product: ExtendedProductModel;
  style?: React.CSSProperties;
}

export const ProductsTableRow: React.FC<ProductsTableRowProps> = observer(({ product, style }) => {
  const navigate = useNavigate();

  const { attributesStore, productsStore, localeStore, modalsStore } = useStores();

  const [initialValues] = useState(() =>
    getAttributesValues(attributesStore.columnsAttributes, product),
  );
  const [values, setValues] = useState<Record<string, string | Descendant[]>>(initialValues);

  const { handleShare } = useShare();

  const productPayload = useMemo(() => {
    const obj: ProductRequestModel = {
      title: product.title,
      parameters: getAttributesForRequest(values, attributesStore.attributes, product.parameters),
    };

    return obj;
  }, [values]);

  useDidUpdate(() => {
    setValues(getAttributesValues(attributesStore.columnsAttributes, product));
  }, [attributesStore.columnsAttributes.length, product.extended.loading, product]);

  const handleToggleSelect = () => productsStore.toggleProductsList(product, "selected");

  const isUploading =
    product.images.findIndex((media) => media?.status === PendingUploadStatus.pending) >= 0;
  const getPreview = () => {
    let result;
    if (product.images.length !== 0) {
      result = getMediaPreviewFromProduct(product.images[0]);
    }

    return result;
  };

  const handleEdit = () => {
    navigate(`/product/${product._id}/edit`);
  };

  const handleDelete = () => {
    modalsStore.open("DeleteProductModal", { products: [product] });
  };

  const handleChangeValue = (name: string, value: string | Descendant[]) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleResetValue = (name: string) => {
    setValues((prev) => ({
      ...prev,
      [name]: initialValues[name],
    }));
  };

  const handleShareProduct = () => {
    handleShare({
      resourceType: ResourceType.PRODUCT,
      resourceIds: [product._id],
      shareType: ShareType.SHARE_PRODUCT,
    });
  };

  return (
    <TableRow
      className={styles.container}
      style={style}
      data-name={`product-tr-${product.title}`}
      data-cy="table-row"
    >
      <TableTitleSection className={styles.tableBodyTitle}>
        <TableCell className={styles.checkboxCell}>
          <Checkbox
            className={styles.checkbox}
            checked={product.extended.selected}
            onChange={handleToggleSelect}
            data-cy="products-table-row-checkbox"
            disableError
          />
        </TableCell>
        <TableCell data-cy="product-tc-media" className={styles.media} onClick={handleEdit}>
          <MediaPreview
            src={getPreview()}
            totalImages={product.images.length}
            type={product?.images[0]?.type}
            isUploading={isUploading}
          />
        </TableCell>
        <TableCell
          onClick={handleEdit}
          className={styles.title}
          data-cy="sku"
          data-name={values.title}
        >
          <Typography size="extraSmall" color="textSecondary">
            {values.title}
          </Typography>
        </TableCell>
      </TableTitleSection>

      {attributesStore.columnsAttributes.map((attribute) => {
        if (attribute.name === "title") return null;

        return (
          <Attribute
            key={attribute.id}
            productPayload={productPayload}
            productId={product._id}
            attribute={attribute}
            initialValue={initialValues[attribute.name]}
            value={values[attribute.name]}
            onChange={(value: string | Descendant[]) => handleChangeValue(attribute.name, value)}
            onReset={() => handleResetValue(attribute.name)}
          />
        );
      })}
      <TableCell className={styles.views}>
        <Typography size="extraSmall" color="textSecondary">
          {product.views}
        </Typography>
      </TableCell>
      <LastCell className={styles.actions} data-cy="product-actions">
        <TableActionIcon
          appearance="primaryGhost"
          label={localeStore.t("common.buttons.share")}
          icon={ShareSVG}
          onClick={handleShareProduct}
          disabled={!product.link.uuid || isUploading}
        />
        <TableActionIcon
          appearance="primaryGhost"
          label={localeStore.t("common.buttons.edit")}
          icon={PencilSVG}
          onClick={handleEdit}
        />
        <TableActionIcon
          appearance="errorGhost"
          label={localeStore.t("common.buttons.delete")}
          icon={TrashSVG}
          onClick={handleDelete}
        />
      </LastCell>
    </TableRow>
  );
});
